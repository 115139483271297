@charset "UTF-8";
/*-----------------------------------------------------------------------------------
    Note: This is Main Style Scss File.
-----------------------------------------------------------------------------------
	CSS INDEX
	===================
    01. Common
    02. Header
    03. Banner
    04. Service
    05. Feature
    05. Portdolio
    07. Counter
    08. Team
    09. Testimonial
    10. Call to action
    11. Latest Post
    12. About
    13. Why Choose
    14. Contact
    15. Intro Video
    16. Accordion
    17. history
    18. Brand
    19. Page title
    20. Shop
    21. Blog
    22. Pricing
    23. Tile Gallery
    24. Comming Soon
    25. Privacy
-----------------------------------------------------------------------------------*/
/*=======  COMMON CSS  =======*/
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@100;300;400;500;700&family=Roboto&display=swap");

/*@font-face {
  font-family: "Alegreya Sans";
  src: url("../fonts/Alegreya Sans.eot");
  src: url("../fonts/Alegreya Sans.eot") format("embedded-opentype"), url("../fonts/Alegreya Sans.woff2") format("woff2"), url("../fonts/Alegreya Sans.woff") format("woff"), url("../fonts/Alegreya Sans.ttf") format("truetype"), url("../fonts/Alegreya Sans.svg#Alegreya Sans") format("svg");
}*/

:root {
  --fontFamily: 'Alegreya Sans', sans-serif;
  --headingFontFamily: 'Alegreya Sans', sans-serif;
  --primaryColor: #63a3c9;
  --secondaryColor: #002249;
  --paragraphColor: #666666;
  --whiteColor: #ffffff;
  --blackColor: #1a1e42;
  --transition: .5s;
  --fontSize: 16px;
}

body {
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
  font-size: 18px;
  line-height: 1.8;
  font-family: "Alegreya Sans", sans-serif;
  color: #616161;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.slick-initialized .slick-slide:focus,
input:focus,
textarea:focus,
select:focus,
button:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

::-webkit-scrollbar {
  background-color: #ccc;
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #002249;
}

.slick-slide img {
  display: inline-block;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0px;
  color: #002249;
  font-family: "Alegreya Sans";
  text-align: start;
  unicode-bidi: plaintext;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

ul li{
  text-align: start;
  unicode-bidi: plaintext;
}

p {
  margin: 0px;
  text-align: start;
  unicode-bidi: plaintext;
}

p.text-color-2 {
  color: #81a3bb;
}

p.text-color-3 {
  color: #69a1bb;
}

p.fs-16 {
  font-size: 18px;
}

a {
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: #0c59db;
  text-decoration: none;
  text-align: start;
  unicode-bidi: plaintext;
}

/*======= Input Styles =======*/
input,
textarea,
select {
  width: 100%;
  height: 70px;
  background-color: transparent;
  color: #81a3bb;
  padding: 0 30px;
  font-size: 16px;
  border: none;
}

.selectLang{
  color: #002249;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0px 0px 0px 30px;
  margin: 0 5px;
  font-family: "Alegreya Sans";
  opacity: 1;
  display: block;
  visibility: visible;
  z-index: 9999;
  border: 0;
  appearance: unset !important;
}

textarea {
height: 170px;
display: inherit;
padding-top: 20px;
}

::-webkit-input-placeholder {
color: inherit;
opacity: 1;
}

::-moz-placeholder {
color: inherit;
opacity: 1;
}

:-ms-input-placeholder {
color: inherit;
opacity: 1;
}

::-ms-input-placeholder {
color: inherit;
opacity: 1;
}

::placeholder {
color: inherit;
opacity: 1;
}

input[type=search]::-ms-clear {
display: none;
width: 0;
height: 0;
}

input[type=search]::-ms-reveal {
display: none;
width: 0;
height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
display: none;
}

input[type=checkbox], input[type=radio] {
height: auto;
width: auto;
}

.input-group {
position: relative;
}

.input-group input,
.input-group textarea,
.input-group select {
padding-right: 40px;
}

.input-group .icon {
position: absolute;
right: 30px;
z-index: 2;
top: 50%;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
color: #81a3bb;
line-height: 1;
}

.input-group.textarea-group .icon {
-webkit-transform: translateY(0);
-ms-transform: translateY(0);
transform: translateY(0);
top: 20px;
}

/*======= Common Class =======*/
@media (min-width: 1400px) {
  .container-1470 {
    max-width: 1470px;
  }
}

@media (min-width: 1200px) {
  .header-full-width .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

}

.section-gap {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section-gap-top {
  padding-top: 120px;
}

.section-gap-bottom {
  padding-bottom: 120px;
}

.section-gap-half {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-gap-top-half {
  padding-top: 60px;
}

.section-gap-bottom-half {
  padding-bottom: 60px;
}

.section-gapTop-half {
  padding-top: 20px;
}

.section-title .title-tag {
  color: #0c59db;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 5px;
  z-index: 1;
  position: relative;
  letter-spacing: 2px;
}

.section-title .title {
  font-size: 40px;
  font-weight: 700;
  position: relative;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title .title {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .section-title .title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .section-title .title {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .section-title .title {
    font-size: 26px;
  }
}

.section-title.white-color .title-tag,
.section-title.white-color .title,
.section-title.white-color p {
  color: #fff;
}

.section-title.with-right-border .title-tag {
  text-transform: capitalize;
  letter-spacing: 0;
  padding-right: 100px;
  position: relative;
}

@media (max-width: 575px) {
  .section-title.with-right-border .title-tag {
    padding-right: 0;
    padding-top: 10px;
  }
}

.section-title.with-right-border .title-tag::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  height: 2px;
  width: 70px;
  background: #0c59db;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 575px) {
  .section-title.with-right-border .title-tag::before {
    top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    right: auto;
    left: 0;
    width: 50px;
  }
}

.section-title-two {
  position: relative;
  z-index: 2;
}

.section-title-two .title-tag {
  color: #0c59db;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 5px;
}

.section-title-two .title {
  font-size: 70px;
  font-weight: 700;
  text-transform: capitalize;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title-two .title {
    font-size: 54px;
  }
}

@media (max-width: 991px) {
  .section-title-two .title {
    font-size: 46px;
  }
}

@media (max-width: 575px) {
  .section-title-two .title {
    font-size: 28px;
  }
}

.section-title-two .big-text {
  position: absolute;
  font-size: 180px;
/*  font-size: 80px;*/
  font-family: "Alegreya Sans";
  font-weight: 700;
  bottom: 20px;
  left: 0;
  color: #f5f6f8;
  z-index: -1;
  line-height: 1;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title-two .big-text {
    font-size: 140px;
  }
}

@media (max-width: 991px) {
  .section-title-two .big-text {
    font-size: 120px;
  }
}

@media (max-width: 575px) {
  .section-title-two .big-text {
    font-size: 100px;
  }
}

.grayscale-logo{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 40%;
}

.section-title-two.text-center .big-text {
  left: 50%;
/*  left: 24%;*/
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.section-title-two.text-right .big-text {
  left: auto;
  right: 0;
}

.section-title-two.white-color .title-tag,
.section-title-two.white-color .title {
  color: #fff;
}

.soft-blue-bg .section-title-two .big-text {
  color: #eaf2f8;
}

.soft-blue-bg {
  background-color: #f5faff;
}

.primary-bg {
  background-color: #0c59db;
}

.secondary-bg {
  background-color: #002249;
}

.main-btn {
  padding: 22px 45px;
  background-color: #0c59db;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  position: relative;
  z-index: 1;
  border: none;
}

.main-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  z-index: -1;
  background-color: #002249;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}

.main-btn:hover {
  color: #fff;
}

.main-btn:hover::before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}

.main-btn i {
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.main-btn.main-btn-2 {
  background-color: #b7c3d6;
}

.main-btn.main-btn-3 {
  background-color: #fff;
  color: #0c59db;
}

.main-btn.main-btn-3:hover {
  color: #fff;
}

.main-btn.small-size {
  padding: 22px 40px;
  font-size: 15px;
}

.main-btn.icon-right i {
  margin-right: 0;
  margin-left: 5px;
}

.main-btn.rounded-btn, .main-btn.rounded-btn::before {
  border-radius: 5px;
}

.main-btn.transparent-border-btn {
  background-color: transparent;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.main-btn.transparent-border-btn::before {
  background-color: #0c59db;
}

.main-btn.transparent-border-btn:hover {
  border-color: #0c59db;
}

.page-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.page-pagination li {
  margin: 0 5px;
}

.page-pagination a {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-color: #fff;
  color: #70a3bb;
  font-size: 14px;
  font-weight: 700;
  background-color: #fff;
}

.page-pagination a.active, .page-pagination a:hover {
  background-color: #0c59db;
  color: #fff;
}

.page-pagination.blog-pagination a {
  background-color: #f5faff;
}

.page-pagination.blog-pagination a.active, .page-pagination.blog-pagination a:hover {
  background-color: #0c59db;
  color: #fff;
}

.bg-cover {
  background-size: cover;
  background-position: center;
}

/*======= Preloader =======*/
div#preloader {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

div#preloader .loader-cubes {
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  position: absolute;
  -webkit-transform: rotateZ(45deg);
  -ms-transform: rotate(45deg);
  transform: rotateZ(45deg);
}

div#preloader .loader-cubes .loader-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

div#preloader .loader-cubes .loader-cube::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  background: #0c59db;
}

div#preloader .loader-cubes .loader-cube.loader-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  -ms-transform: scale(1.1) rotate(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

div#preloader .loader-cubes .loader-cube.loader-cube2::before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

div#preloader .loader-cubes .loader-cube .loader-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  -ms-transform: scale(1.1) rotate(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

div#preloader .loader-cubes .loader-cube .loader-cube4::before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

div#preloader .loader-cubes .loader-cube .loader-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  -ms-transform: scale(1.1) rotate(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

div#preloader .loader-cubes .loader-cube .loader-cube3::before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

/*=======  Margin Top =======*/
.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

/*=======  Margin Bottom =======*/
.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

/*=======  Padding Top =======*/
.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

/*=======  Padding Bottom =======*/
.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

@media (max-width: 1199px) {
  .mb-lg-gap-30 {
    margin-bottom: 30px;
  }
}

@media (max-width: 1199px) {
  .mb-lg-gap-40 {
    margin-bottom: 40px;
  }
}

@media (max-width: 1199px) {
  .mb-lg-gap-50 {
    margin-bottom: 50px;
  }
}

@media (max-width: 1199px) {
  .mb-lg-gap-60 {
    margin-bottom: 60px;
  }
}

@media (max-width: 1199px) {
  .mt-lg-gap-30 {
    margin-top: 30px;
  }
}

@media (max-width: 1199px) {
  .mt-lg-gap-40 {
    margin-top: 40px;
  }
}

@media (max-width: 1199px) {
  .mt-lg-gap-50 {
    margin-top: 50px;
  }
}

@media (max-width: 991px) {
  .mt-md-gap-60 {
    margin-top: 60px;
  }
}

@media (max-width: 1199px) {
  .mb-lg-gap-30 {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .mb-md-gap-30 {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .mb-md-gap-40 {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .mb-md-gap-50 {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .mb-md-gap-60 {
    margin-bottom: 60px;
  }
}

@media (max-width: 991px) {
  .mt-md-gap-30 {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .mt-md-gap-40 {
    margin-top: 40px;
  }
}

@media (max-width: 991px) {
  .mt-md-gap-50 {
    margin-top: 50px;
  }
}

@media (max-width: 991px) {
  .mt-md-gap-60 {
    margin-top: 60px;
  }
}

@media (max-width: 575px) {
  .mb-xs-gap-30 {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .mb-xs-gap-40 {
    margin-bottom: 40px;
  }
}

/*=======  Animations  =======*/
@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0);
    transform: perspective(140px) rotateX(0);
    opacity: 1;
  }

  100%, 90% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0);
    transform: perspective(140px) rotateX(0);
    opacity: 1;
  }

  100%, 90% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@-webkit-keyframes pulseBtnBefore {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulseBtnBefore {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes pulseBtnAfter {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulseBtnAfter {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateTwo {
  0% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes rotateTwo {
  0% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
/*=======  Header  =======*/
.header-topbar {
  border-bottom: 2px solid #ebebeb;
  padding: 15px 0;
}

.header-topbar .contact-info li {
  display: inline-block;
}

.header-topbar .contact-info li:not(:last-child) {
  margin-right: 30px;
}

.header-topbar .contact-info li a {
  color: #616161;
  font-size: 14px;
}

.header-topbar .contact-info li i {
  margin-right: 5px;
  color: #0c59db;
}

.header-topbar .social-icon li {
  display: inline-block;
}

.header-topbar .social-icon li:not(:first-child) {
  margin-left: 20px;
}

.header-topbar .social-icon li a {
  color: #aeaeae;
  font-size: 16px;
}

.header-topbar .social-icon li a:hover {
  color: #0c59db;
}

.header-navigation{
  background-color: #ffffffc5;
  z-index :2;
  position: relative;
}

.header-navigation .primary-menu li {
  display: inline-block;
  line-height: 80px;
  position: relative;
}

.header-navigation .primary-menu li .dd-trigger {
  font-size: 12px;
  font-weight: 500;
  color: #4f79a9;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 1199px) {
  .header-navigation .primary-menu li .dd-trigger {
    right: -5px;
  }
}

.header-navigation .primary-menu li a {
  font-size: 18px;
  color: #002249;
  padding: 10px;
  font-family: "Alegreya Sans";
  text-transform: capitalize;
  margin: 0 10px;
}

@media (max-width: 1199px) {
  .header-navigation .primary-menu li a {
    padding: 8px;
    margin: 0 5px;
    font-size: 15px;
  }
}

.header-navigation .primary-menu li .submenu {
  position: absolute;
  left: -40px;
  top: 110%;
  width: 200px;
  background-color: #fff;
  transition: all 0.3s ease-out 0s;
  z-index: 99;
  height: auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-top: 2px solid #0c59db;
  opacity: 0;
  visibility: hidden;
}

.header-navigation .primary-menu li .submenu li {
  line-height: 1;
  margin: 0;
  display: block;
}

.header-navigation .primary-menu li .submenu li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.header-navigation .primary-menu li .submenu li a {
  padding: 14px 24px;
  font-size: 16px;
  display: block;
  margin: 0;
  line-height: 22px;
}

.header-navigation .primary-menu li .submenu li a:hover {
  background-color: #0c59db;
  color: #fff;
}

.header-navigation .primary-menu li .submenu li .dd-trigger {
  right: 20px;
}

.header-navigation .primary-menu li .submenu li a:hover ~ .dd-trigger {
  color: #fff;
}

.header-navigation .primary-menu li .submenu li .submenu {
  left: 100%;
  top: 20%;
}

.header-navigation .primary-menu li .submenu li:hover > .submenu {
  top: 0;
}

.header-navigation .primary-menu li:hover > .submenu {
  visibility: visible;
  top: 100%;
  opacity: 1;
}

.header-navigation .primary-menu li.current > a, .header-navigation .primary-menu li.current > .dd-trigger,
.header-navigation .primary-menu li a:hover,
.header-navigation .primary-menu li a:hover ~ .dd-trigger {
  color: #0c59db;
}

@media (max-width: 991px) {
  .header-navigation .primary-menu li.current > a, .header-navigation .primary-menu li.current > .dd-trigger,
  .header-navigation .primary-menu li a:hover,
  .header-navigation .primary-menu li a:hover ~ .dd-trigger {
    color: #fff;
  }
}

@media (max-width: 560px) {
  .header-navigation .site-logo {
    max-width: 150px;
  }
  .header-topbar .contact-info li:not(:last-child){
    float: left;
  }
  .header-topbar .social-icon li:not(:first-child){
    float: right;
  }
}

.header-navigation .site-logo .sticky-logo {
  display: none;
}

.header-navigation .header-extra {
  /*margin-left: 20px;*/
  padding-right: 15px;
}

.header-navigation .cart-icon,
.header-navigation .search-icon {
  font-size: 18px;
  color: #002249;
  margin-right: 30px;
}

@media (max-width: 1199px) {
  .header-navigation .cart-icon,
  .header-navigation .search-icon {
    margin-right: 20px;
  }
}

.header-navigation .cart-icon:hover,
.header-navigation .search-icon:hover {
  color: #0c59db;
}

.header-navigation .search-widget .search-form {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  background-color: rgba(35, 35, 35, 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  will-change: transform, opacity;
  height: 0;
  z-index: -1;
  opacity: 0;
}

.header-navigation .search-widget .search-form .search-close {
  font-size: 30px;
  color: #fff;
  position: absolute;
  right: 50px;
  top: 50px;
  line-height: 1;
}

.header-navigation .search-widget .search-form form {
  width: 50%;
}

.header-navigation .search-widget .search-form input {
  background-color: transparent;
  font-size: 20px;
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
  padding: 0 30px;
}

.header-navigation .search-widget .search-form.opened {
  height: 100vh;
  opacity: 1;
  z-index: 99;
}

.header-navigation .offcanvas-icon,
.header-navigation .nav-toggler {
  position: relative;
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
  cursor: pointer;
}

.header-navigation .offcanvas-icon span,
.header-navigation .nav-toggler span {
  background-color: #0c59db;
  height: 2px;
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
}

.header-navigation .nav-toggler {
  display: none;
}

.header-navigation .nav-toggler.menu-opened span:nth-child(1) {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
  top: 8px;
}

.header-navigation .nav-toggler.menu-opened span:nth-child(2) {
  opacity: 0;
}

.header-navigation .nav-toggler.menu-opened span:nth-child(3) {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
  top: -8px;
}

.header-navigation .nav-close {
  display: none;
  font-size: 20px;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: 1;
}

.header-navigation .navbar-btn {
  margin-left: 40px;
}

@media (max-width: 1199px) {
  .header-navigation .navbar-btn {
    margin-left: 30px;
  }
}

@media (max-width: 767px) {
  .header-navigation .navbar-btn {
    margin-left: 20px;
  }
}

@media (max-width: 575px) {
  .header-navigation .navbar-btn {
    display: none;
  }
}

.header-navigation .navbar-btn a {
  font-size: 15px;
  font-weight: 700;
  background-color: #0c59db;
  color: #fff;
  border: 2px solid #0c59db;
  border-radius: 5px;
  padding: 15px 25px;
}

@media (max-width: 1199px) {
  .header-navigation .navbar-btn a {
    padding: 15px 20px;
  }
}

.header-navigation .navbar-btn a i {
  margin-left: 10px;
  position: relative;
  top: 2px;
}

@media (max-width: 1199px) {
  .header-navigation .navbar-btn a i {
    margin-left: 5px;
  }
}

.header-navigation .navbar-btn a:hover {
  background-color: transparent;
  color: #0c59db;
}

.header-two {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
}

.header-two .header-topbar {
  border-bottom: 1px solid #99c2f6;
}

.header-two .header-topbar .contact-info li a,
.header-two .header-topbar .contact-info li i {
  color: #4f79a9;
}

.header-two .header-topbar .social-icon li a {
  color: #4f79a9;
}

.header-two .header-topbar .social-icon li a:hover {
  color: #0c59db;
}

.header-two .header-navigation .primary-menu li .dd-trigger,
.header-three .header-navigation .primary-menu li .dd-trigger {
  right: 5px;
}

@media (max-width: 1199px) {
  .header-two .header-navigation .primary-menu li .dd-trigger,
  .header-three .header-navigation .primary-menu li .dd-trigger {
    right: 0;
  }
}

.header-two .header-navigation .primary-menu li a,
.header-three .header-navigation .primary-menu li a {
  color: #4f79a9;
  text-transform: uppercase;
  font-size: 16px;
  padding: 10px;
  margin: 0 15px;
}

@media (max-width: 1199px) {
  .header-two .header-navigation .primary-menu li a,
  .header-three .header-navigation .primary-menu li a {
    font-size: 15px;
    margin: 0 5px;
  }
}

.header-two .header-navigation .primary-menu li .submenu li a,
.header-three .header-navigation .primary-menu li .submenu li a {
  font-size: 14px;
  line-height: 22px;
}

.header-two .header-navigation .primary-menu li.current > a, .header-two .header-navigation .primary-menu li.current > .dd-trigger,
.header-two .header-navigation .primary-menu li a:hover,
.header-two .header-navigation .primary-menu li a:hover ~ .dd-trigger,
.header-three .header-navigation .primary-menu li.current > a,
.header-three .header-navigation .primary-menu li.current > .dd-trigger,
.header-three .header-navigation .primary-menu li a:hover,
.header-three .header-navigation .primary-menu li a:hover ~ .dd-trigger {
  color: #0c59db;
}

@media (max-width: 991px) {
  .header-two .header-navigation .primary-menu li.current > a, .header-two .header-navigation .primary-menu li.current > .dd-trigger,
  .header-two .header-navigation .primary-menu li a:hover,
  .header-two .header-navigation .primary-menu li a:hover ~ .dd-trigger,
  .header-three .header-navigation .primary-menu li.current > a,
  .header-three .header-navigation .primary-menu li.current > .dd-trigger,
  .header-three .header-navigation .primary-menu li a:hover,
  .header-three .header-navigation .primary-menu li a:hover ~ .dd-trigger {
    color: #fff;
  }
}

.header-two .header-navigation .nav-toggler,
.header-three .header-navigation .nav-toggler {
  border: 1px solid #0c59db;
  height: 50px;
  width: 60px;
  padding: 10px 12px;
  border-radius: 5px;
}

.header-two .header-navigation .navbar-btn,
.header-three .header-navigation .navbar-btn {
  margin-left: 0;
}

@media (max-width: 991px) {
  .header-two .header-navigation .navbar-btn,
  .header-three .header-navigation .navbar-btn {
    margin-left: 40px;
  }
}

.header-two .header-navigation .navbar-btn a,
.header-three .header-navigation .navbar-btn a {
  padding: 15px 50px;
  border-radius: 5px;
}

@media (max-width: 1199px) {
  .header-two .header-navigation .navbar-btn a,
  .header-three .header-navigation .navbar-btn a {
    padding: 15px 35px;
  }
}

.header-three .header-topbar {
  border: none;
  background-color: #0c59db;
  position: relative;
  padding: 10px 0;
}

.header-three .header-topbar::before, .header-three .header-topbar::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 400px;
  background: #fff;
  top: 0;
  left: calc(50% - 25px);
  -webkit-transform: skew(45deg);
  -ms-transform: skew(45deg);
  transform: skew(45deg);
}

@media (max-width: 1399px) {
  .header-three .header-topbar::before, .header-three .header-topbar::after {
    width: 250px;
  }
}

@media (max-width: 1199px) {
  .header-three .header-topbar::before, .header-three .header-topbar::after {
    width: 200px;
  }
}

@media (max-width: 991px) {
  .header-three .header-topbar::before, .header-three .header-topbar::after {
    display: none;
  }
}

.header-three .header-topbar::after {
  left: auto;
  right: calc(50% - 25px);
  -webkit-transform: skew(-45deg);
  -ms-transform: skew(-45deg);
  transform: skew(-45deg);
}

.header-three .header-topbar .top-menu li {
  display: inline-block;
}

.header-three .header-topbar .top-menu li:not(:last-child) {
  margin-right: 30px;
}

.header-three .header-topbar .top-menu li a {
  color: #fff;
  font-size: 14px;
}

.header-three .header-topbar .social-icon li a {
  color: #fff;
  font-size: 15px;
}

.header-four {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.header-four .header-navigation .primary-menu li .dd-trigger {
  right: 5px;
  color: #fff;
}

@media (max-width: 1199px) {
  .header-four .header-navigation .primary-menu li .dd-trigger {
    right: 0;
  }
}

.header-four .header-navigation .primary-menu li a {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 10px;
  margin: 0 15px;
}

@media (max-width: 1199px) {
  .header-four .header-navigation .primary-menu li a {
    font-size: 15px;
    margin: 0 5px;
  }
}

.header-four .header-navigation .primary-menu li .submenu {
  border: none;
}

.header-four .header-navigation .primary-menu li .submenu li a {
  font-size: 14px;
  color: #616161;
}

.header-four .header-navigation .primary-menu li .submenu li a:hover {
  background-color: #0c59db;
  color: #fff;
}

.header-four .header-navigation .primary-menu li .submenu li .dd-trigger {
  color: #616161;
}

.header-four .header-navigation .primary-menu li.current > a, .header-four .header-navigation .primary-menu li.current > .dd-trigger,
.header-four .header-navigation .primary-menu li a:hover,
.header-four .header-navigation .primary-menu li a:hover ~ .dd-trigger {
  color: #fff;
}

.header-four .header-navigation .nav-toggler {
  border: 1px solid #fff;
  height: 50px;
  width: 60px;
  padding: 10px 12px;
  border-radius: 5px;
}

.header-four .header-navigation .nav-toggler span {
  background-color: #fff;
}

.header-four .header-navigation .navbar-btn {
  margin-left: 0;
}

@media (max-width: 991px) {
  .header-four .header-navigation .navbar-btn {
    margin-left: 40px;
  }
}

.header-four .header-navigation .navbar-btn a {
  padding: 15px 50px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid rgba(245, 250, 255, 0.1);
}

@media (max-width: 1199px) {
  .header-four .header-navigation .navbar-btn a {
    padding: 15px 35px;
  }
}

.header-four .header-navigation .navbar-btn a:hover {
  background-color: #0c59db;
  color: #fff;
  border-color: #0c59db;
}

.sticky-header.sticky-on .header-navigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  -webkit-animation: sticky 1.2s;
  animation: sticky 1.2s;
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
}

.sticky-header.sticky-on .header-navigation .sticky-logo {
  display: block;
}

.sticky-header.sticky-on .header-navigation .main-logo {
  display: none;
}

.sticky-header.sticky-on .header-navigation:not(.breakpoint-on) .primary-menu > li {
  line-height: 80px;
}

/*.sticky-header.sticky-on .header-navigation:not(.breakpoint-on) .primary-menu > li a,
.sticky-header.sticky-on .header-navigation:not(.breakpoint-on) .primary-menu > li .dd-trigger {
  color: #002249;
}*/

.sticky-header.sticky-on .header-navigation:not(.breakpoint-on) .primary-menu > li.current > a, .sticky-header.sticky-on .header-navigation:not(.breakpoint-on) .primary-menu > li.current > .dd-trigger,
.sticky-header.sticky-on .header-navigation:not(.breakpoint-on) .primary-menu > li a:hover,
.sticky-header.sticky-on .header-navigation:not(.breakpoint-on) .primary-menu > li a:hover ~ .dd-trigger {
  color: #0c59db;
}

.sticky-header.sticky-on .header-navigation:not(.breakpoint-on) .primary-menu > li .submenu li a:hover {
  color: #fff;
}

.sticky-header.sticky-on.header-four .navbar-btn a {
  background-color: #0c59db;
}

.sticky-header.sticky-on.header-four .nav-toggler {
  border: 1px solid #0c59db;
}

.sticky-header.sticky-on.header-four .nav-toggler span {
  background-color: #0c59db;
}

.header-navigation.breakpoint-on {
  padding: 20px 0;
}

.header-navigation.breakpoint-on .nav-toggler {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-navigation.breakpoint-on .site-nav-menu {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 300px;
  padding: 50px 25px;
  background-color: #002249;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: scroll;
  left: -300px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.header-navigation.breakpoint-on .site-nav-menu .nav-close {
  display: block;
}

.header-navigation.breakpoint-on .site-nav-menu::-webkit-scrollbar {
  background-color: #002249;
}

.header-navigation.breakpoint-on .site-nav-menu.menu-opened {
  left: 0;
  visibility: visible;
  opacity: 1;
}

.header-navigation.breakpoint-on .site-nav-menu::-webkit-scrollbar {
  width: 5px;
}

.header-navigation.breakpoint-on .site-nav-menu .primary-menu li {
  margin: 0;
  padding: 0;
  display: block;
}

.header-navigation.breakpoint-on .site-nav-menu .primary-menu li a {
  color: #fff;
  display: block;
  padding: 15px 25px 15px 0;
  font-size: 15px;
  line-height: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header-navigation.breakpoint-on .site-nav-menu .primary-menu li select {
  color: #fff;
  display: block;
  font-size: 15px;
  margin: 0;
  line-height: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header-navigation.breakpoint-on .site-nav-menu .primary-menu li .dd-trigger {
  display: block;
  top: 0;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
  right: 0 !important;
  height: 45px;
  width: 45px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 45px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.header-navigation.breakpoint-on .site-nav-menu .primary-menu li .dd-trigger i {
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.header-navigation.breakpoint-on .site-nav-menu .primary-menu li .submenu {
  padding-left: 15px;
  -webkit-transition: 0;
  -o-transition: 0;
  transition: 0;
  border: none;
}

.header-navigation.breakpoint-on .site-nav-menu .primary-menu li .submenu a {
  color: #fff !important;
  display: block;
  padding: 15px 25px 15px 0;
  line-height: 1;
}

.header-navigation.breakpoint-on .site-nav-menu .primary-menu li .submenu a:hover {
  background-color: transparent !important;
}

.header-navigation.breakpoint-on .site-nav-menu .primary-menu li .submenu,
.header-navigation.breakpoint-on .site-nav-menu .primary-menu li li .submenu {
  position: unset;
  top: 0;
  width: 100%;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  visibility: visible;
  opacity: 1;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: none;
}

.header-navigation.breakpoint-on .site-nav-menu .primary-menu li.current::before {
  position: absolute;
  left: -25px;
  top: 2px;
  bottom: 2px;
  width: 2px;
  background-color: #fff;
  content: "";
}

/*=======  Banner  =======*/
.banner-slider {
  overflow: hidden;
}

.banner-slider .banner-text .title-tag {
  color: #002249;
  font-size: 24px;
  font-weight: 700;
/*  text-transform: capitalize;*/
  letter-spacing: 2px;
  padding-bottom: 10px;
}

@media (max-width: 575px) {
  .banner-slider .banner-text .title-tag {
    font-size: 18px;
  }
}

.banner-slider .banner-text .title {
  font-size: 70px;
  line-height: 1.1;
}

@media (max-width: 1399px) {
  .banner-slider .banner-text .title {
    font-size: 70px;
  }
}

@media (max-width: 1199px) {
  .banner-slider .banner-text .title {
    font-size: 62px;
  }
}

@media (max-width: 767px) {
  .banner-slider .banner-text .title {
    font-size: 48px;
  }
}

@media (max-width: 575px) {
  .banner-slider .banner-text .title {
    font-size: 32px;
    line-height: 1.2;
  }
}

.banner-slider .banner-text p {
  font-weight: 700;
  font-size: 19px;
  color: #002249;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 0px;
  opacity: 0;
}

@media (max-width: 767px) {
  .banner-slider .banner-text p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .banner-slider .banner-text p {
    font-size: 15px;
  }
}

.banner-slider .banner-img {
  text-align: right;
  position: relative;
  z-index: 2;
}

.banner-slider .slick-arrow {
  position: absolute;
  top: 50%;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  background: rgba(12, 89, 219, 0.5);
  color: #fff;
  z-index: 2;
  font-size: 20px;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 991px) {
  .banner-slider .slick-arrow {
    top: auto;
    bottom: 0;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    opacity: 1;
    visibility: visible;
  }
}

.banner-slider .slick-arrow.prev {
  left: 0;
}

@media (max-width: 991px) {
  .banner-slider .slick-arrow.prev {
    left: auto;
    right: 75px;
  }
}

.banner-slider .slick-arrow.next {
  right: 0;
}

@media (max-width: 991px) {
  .banner-slider .slick-arrow.next {
    right: 15px;
  }
}

.banner-slider .slick-arrow:hover {
  background: #0c59db;
}

.banner-slider:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.banner-slider:hover .slick-arrow.prev {
  left: 20px;
}

@media (max-width: 991px) {
  .banner-slider:hover .slick-arrow.prev {
    left: auto;
    right: 75px;
  }
}

.banner-slider:hover .slick-arrow.next {
  right: 20px;
}

@media (max-width: 991px) {
  .banner-slider:hover .slick-arrow.next {
    right: 15px;
  }
}

.banner-slider.banner-slider-one {
  padding: 170px 0;
  background-image: url(../img/banner/banner-one-bg.jpg);
  background-color: #f5faff;
  background-size: cover;
  background-position: center;
  position: relative;
}

@media (max-width: 991px) {
  .banner-slider.banner-slider-one {
    padding: 140px 0;
  }
}

.banner-slider.banner-slider-two .single-banner {
  position: relative;
  background-size: cover;
  background-position: center;
  z-index: 1;
  padding: 190px 0;
}

@media (max-width: 991px) {
  .banner-slider.banner-slider-two .single-banner {
    padding: 140px 0;
  }
}

.banner-slider.banner-slider-two .single-banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #0c59db;
  opacity: 0.8;
}

.banner-slider.banner-slider-two .banner-text .title-tag,
.banner-slider.banner-slider-two .banner-text .title,
.banner-slider.banner-slider-two .banner-text p {
  color: #fff;
}

.banner-slider.banner-slider-two .banner-text .main-btn {
  background-color: #fff;
  color: #0c59db;
}

.banner-slider.banner-slider-two .banner-text .main-btn:hover {
  color: #fff;
}

.banner-slider.banner-slider-two .slick-arrow {
  background-color: rgba(255, 255, 255, 0.8);
  color: #0c59db;
}

.banner-slider.banner-slider-two .slick-arrow:hover {
  background-color: #fff;
}

.banner-slider.banner-slider-three .single-banner {
  position: relative;
  background-size: cover;
  background-position: center;
  z-index: 1;
  padding: 200px 0;
}

@media (max-width: 991px) {
  .banner-slider.banner-slider-three .single-banner {
    padding: 140px 0;
  }
}

.banner-slider.banner-slider-three .single-banner::before {
/*  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: -webkit-gradient(linear, left top, right top, from(#0c5adb), color-stop(50%, rgba(12, 90, 219, 0.6)), to(rgba(255, 255, 255, 0)));
  background-image: -o-linear-gradient(left, #0c5adb 0%, rgba(12, 90, 219, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(90deg, #0c5adb 0%, rgba(12, 90, 219, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.8;*/
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  background-color: #002249;
  opacity: 0.5;
}

.banner-slider.banner-slider-three .banner-text{
  height: 50vh;
}


.banner-slider.banner-slider-three .banner-text .banner-content{
  position: absolute;
  bottom: 0;
}

.banner-slider.banner-slider-three .banner-text .title-tag,
.banner-slider.banner-slider-three .banner-text .title,
.banner-slider.banner-slider-three .banner-text p {
  color: #fff;
}

.banner-slider.banner-slider-three .banner-text .main-btn {
  background-color: #fff;
  color: #0c59db;
}

.banner-slider.banner-slider-three .banner-text .main-btn:hover {
  color: #fff;
}

.banner-slider.banner-slider-three .slick-arrow {
  background-color: rgba(255, 255, 255, 0.8);
  color: #0c59db;
}

.banner-slider.banner-slider-three .slick-arrow:hover {
  background-color: #fff;
}

.banner-section {
  background: #a9c7ec;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 2;
  padding-top: 300px;
  padding-bottom: 200px;
}

@media (max-width: 1199px) {
  .banner-section {
    padding-bottom: 160px;
  }
}

@media (max-width: 767px) {
  .banner-section {
    padding-top: 260px;
    padding-bottom: 140px;
  }
}

@media (max-width: 575px) {
  .banner-section {
    padding-top: 220px;
    padding-bottom: 120px;
  }
}

.banner-section::before {
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  background-image: url(../img/banner/banner-gradient-bg.png);
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.banner-section .banner-content .title-tag {
  font-size: 24px;
  color: #69a1bb;
}

@media (max-width: 767px) {
  .banner-section .banner-content .title-tag {
    font-size: 18px;
    height: 15rem;
  }
}

@media (max-width: 575px) {
  .banner-section .banner-content .title-tag {
    font-size: 16px;
  }
}

.banner-section .banner-content .title {
  font-size: 80px;
  padding-top: 18px;
  padding-bottom: 34px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .banner-section .banner-content .title {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .banner-section .banner-content .title {
    font-size: 74px;
  }
}

@media (max-width: 767px) {
  .banner-section .banner-content .title {
    font-size: 62px;
  }
}

@media (max-width: 575px) {
  .banner-section .banner-content .title {
    font-size: 42px;
  }
}

.banner-section .banner-content .banner-btns li {
  display: inline-block;
}

.banner-section .banner-content .banner-btns li:not(:last-child) {
  margin-right: 75px;
}

@media (max-width: 1199px) {
  .banner-section .banner-content .banner-btns li:not(:last-child) {
    margin-right: 40px;
  }
}

@media (max-width: 575px) {
  .banner-section .banner-content .banner-btns li:not(:last-child) {
    margin-right: 20px;
  }
}

@media (max-width: 400px) {
  .banner-section .banner-content .banner-btns li:not(:last-child) {
    margin-bottom: 20px;
  }
}

.banner-section .banner-content .banner-btns li a {
  font-size: 24px;
  color: #232323;
  font-weight: 700;
}

@media (max-width: 1199px) {
  .banner-section .banner-content .banner-btns li a {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .banner-section .banner-content .banner-btns li a {
    font-size: 16px;
  }
}

.banner-section .banner-content .banner-btns li a .icon {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  margin-right: 30px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 109, 232, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(0, 109, 232, 0.1);
  position: relative;
  border: 4px solid transparent;
  border-top-color: #ff4f74;
  border-bottom-color: #ff4f74;
  background-color: #f5faff;
  color: #ff4f74;
  font-size: 18px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .banner-section .banner-content .banner-btns li a .icon {
    height: 60px;
    width: 60px;
    margin-right: 20px;
  }
}

.banner-section .banner-content .banner-btns li a .icon::before {
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  left: -5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #ff4f74;
}

.banner-section .banner-content .banner-btns li a .icon::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  right: -5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #ff4f74;
}

.banner-section .banner-content .banner-btns li a.btn-2 .icon {
  border-top-color: #0c59db;
  border-bottom-color: #0c59db;
  color: #0c59db;
}

.banner-section .banner-content .banner-btns li a.btn-2 .icon::before {
  background: #0c59db;
}

.banner-section .banner-content .banner-btns li a.btn-2 .icon::after {
  background: #0c59db;
}

.banner-section .banner-img {
  position: absolute;
  right: -70px;
  bottom: -222px;
  height: 630px;
  width: 630px;
  background: #f3faff;
  border-radius: 50%;
  border: 60px solid #ebf4ff;
  z-index: 1;
}

@media (max-width: 1199px) {
  .banner-section .banner-img {
    height: 500px;
    width: 500px;
  }
}

@media (max-width: 991px) {
  .banner-section .banner-img {
    display: none;
  }
}

.banner-section .banner-img::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  background: #fff;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.banner-section .banner-img img {
  position: absolute;
  top: -135px;
  left: 0;
}

.banner-section-two {
  position: relative;
  z-index: 1;
  padding-top: 200px;
  padding-bottom: 200px;
}

@media (max-width: 991px) {
  .banner-section-two {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}

@media (max-width: 575px) {
  .banner-section-two {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}

.banner-section-two::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  background-color: #002249;
  opacity: 0.5;
}

.banner-section-two .banner-content .title-tag {
  font-size: 30px;
  color: #fff;
  opacity: 0.5;
  position: relative;
  padding-left: 145px;
  padding-right: 145px;
}

@media (max-width: 767px) {
  .banner-section-two .banner-content .title-tag {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .banner-section-two .banner-content .title-tag {
    padding: 0;
    font-size: 20px;
  }
}

.banner-section-two .banner-content .title-tag::before, .banner-section-two .banner-content .title-tag::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 120px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
}

@media (max-width: 767px) {
  .banner-section-two .banner-content .title-tag::before, .banner-section-two .banner-content .title-tag::after {
    width: 80px;
  }
}

@media (max-width: 575px) {
  .banner-section-two .banner-content .title-tag::before, .banner-section-two .banner-content .title-tag::after {
    display: none;
  }
}

.banner-section-two .banner-content .title-tag::after {
  left: auto;
  right: 0;
}

.banner-section-two .banner-content .title {
  font-size: 160px;
  color: #fff;
}

@media (max-width: 1199px) {
  .banner-section-two .banner-content .title {
    font-size: 110px;
  }
}

@media (max-width: 767px) {
  .banner-section-two .banner-content .title {
    font-size: 90px;
    padding-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .banner-section-two .banner-content .title {
    font-size: 52px;
  }
}

.banner-section-two .banner-content p {
  font-size: 24px;
  color: #fff;
  opacity: 0.8;
  margin-top: -10px;
  padding-bottom: 26px;
}

@media (max-width: 767px) {
  .banner-section-two .banner-content p {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .banner-section-two .banner-content p {
    font-size: 18px;
  }
}

.banner-section-two .banner-content .banner-btns {
  margin-top: 15px;
  margin-left: -8px;
  margin-right: -8px;
}

.banner-section-two .banner-content .banner-btns li {
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
}

@media (max-width: 575px) {
  .banner-section-two .banner-content .banner-btns li {
    padding-bottom: 5px;
  }
}

.banner-section-three {
  position: relative;
  z-index: 5;
  padding-top: 275px;
  padding-bottom: 275px;
  background-size: cover;
  background-position: center;
}

.banner-section-three::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  background-color: #002249;
  opacity: 0.9;
}

.banner-section-three .banner-content .title-tag {
  font-size: 30px;
  color: #fff;
  opacity: 0.5;
  position: relative;
  padding-left: 145px;
  padding-right: 145px;
}

@media (max-width: 767px) {
  .banner-section-three .banner-content .title-tag {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .banner-section-three .banner-content .title-tag {
    padding: 0;
    font-size: 20px;
  }
}

.banner-section-three .banner-content .title-tag::before, .banner-section-three .banner-content .title-tag::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 120px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
}

@media (max-width: 767px) {
  .banner-section-three .banner-content .title-tag::before, .banner-section-three .banner-content .title-tag::after {
    width: 80px;
  }
}

@media (max-width: 575px) {
  .banner-section-three .banner-content .title-tag::before, .banner-section-three .banner-content .title-tag::after {
    display: none;
  }
}

.banner-section-three .banner-content .title-tag::after {
  left: auto;
  right: 0;
}

.banner-section-three .banner-content .title {
  font-size: 160px;
  color: #fff;
}

@media (max-width: 1199px) {
  .banner-section-three .banner-content .title {
    font-size: 110px;
  }
}

@media (max-width: 767px) {

  .banner-content{
    height: 15rem;
  }

  .banner-section-three .banner-content .title {
    font-size: 90px;
    padding-bottom: 10px;
  }
}

@media (max-width: 575px) {

  .banner-content{
    height: 15rem;
  }

  .banner-section-three .banner-content .title {
    font-size: 52px;
  }
}

.banner-section-three .banner-content p {
  font-size: 24px;
  color: #fff;
  opacity: 0.8;
  margin-top: -10px;
  padding-bottom: 26px;
}

@media (max-width: 767px) {
  .banner-section-three .banner-content p {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .banner-section-three .banner-content p {
    font-size: 18px;
  }
}

.banner-section-three .banner-shapes .shape-one,
.banner-section-three .banner-shapes .shape-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 45%;
  z-index: -1;
}

.banner-section-three .banner-shapes .shape-two {
  top: 0;
  left: auto;
  right: 0;
  width: 33%;
}

.banner-section-three .brand-slider {
  position: absolute;
  left: 0;
  bottom: 80px;
  right: 0;
  z-index: 99;
}

/*=======  Service  =======*/
.service-item {
  border-radius: 10px;
  background: #fff;
  -webkit-box-shadow: 0px 10px 30px rgba(225, 225, 225, 0.64);
  box-shadow: 0px 10px 30px rgba(225, 225, 225, 0.64);
  padding: 40px 25px 55px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-item {
    padding: 35px 20px 40px;
  }
}

.service-item .icon {
  height: 110px;
  width: 110px;
  line-height: 1;
  background-color: rgba(12, 89, 219, 0.1);
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 20px;
  font-size: 50px;
  color: #0c59db;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-item .icon {
    height: 80px;
    width: 80px;
    font-size: 30px;
  }
}

.service-item.text-center .icon {
  margin-left: auto;
  margin-right: auto;
}

.service-item.color-2 .icon {
  color: #00978e;
  background-color: rgba(0, 151, 142, 0.1);
}

.service-item.color-3 .icon {
  color: #fbb81b;
  background-color: rgba(251, 184, 27, 0.1);
}

.service-item.color-4 .icon {
  color: #ef452e;
  background-color: rgba(239, 69, 46, 0.1);
}

.service-item .title {
  font-size: 22px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 15px;
}

@media (max-width: 1199px) {
  .service-item .title {
    font-size: 18px;
  }
}

.service-item .title a {
  color: #232323;
}

.service-item p {
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.service-item::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/shape/service-dot.png);
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}

.service-item:hover {
  background-color: #0c59db;
  -webkit-box-shadow: 0px 10px 30px rgba(239, 239, 239, 0.79);
  box-shadow: 0px 10px 30px rgba(239, 239, 239, 0.79);
}

.service-item:hover .icon {
  background-color: #fff;
}

.service-item:hover .title a,
.service-item:hover p {
  color: #fff;
}

.service-item:hover::before {
  opacity: 1;
  visibility: visible;
}

.service-item-two {
  background-color: #f5faff;
  padding: 40px 35px;
}

.service-item-two .icon {
  color: #ef452e;
  font-size: 40px;
  line-height: 1;
  margin-bottom: 20px;
}

.service-item-two .title {
  font-size: 26px;
  margin-bottom: 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-item-two .title {
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .service-item-two .title {
    font-size: 22px;
  }
}

.service-item-two .title a {
  color: #232323;
}

.service-item-two.color-1 .icon {
  color: #ef452e;
}

.service-item-two.color-2 .icon {
  color: #fbb81b;
}

.service-item-two.color-3 .icon {
  color: #006de8;
}

.service-item-two.color-4 .icon {
  color: #00978e;
}

.service-item-three {
  -webkit-box-shadow: 0px 10px 29.7px 0.3px rgba(225, 225, 225, 0.64);
  box-shadow: 0px 10px 29.7px 0.3px rgba(225, 225, 225, 0.64);
  padding: 45px 20px 50px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background-color: #fff;
  z-index: 5;
}

.service-item-three .icon {
  font-size: 50px;
  color: #0c59db;
  margin-bottom: 35px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  line-height: 1;
}

.service-item-three .title {
  font-size: 22px;
  margin-bottom: 10px;
}

.service-item-three .title a {
  color: #232323;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-item-three .title {
    font-size: 18px;
  }
}

.service-item-three p {
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.service-item-three .service-link {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background: #0c59db;
  color: #fff;
  font-size: 14px;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.service-item-three::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/shape/service-dot.png);
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}

.service-item-three:hover {
  background-color: #0c59db;
}

.service-item-three:hover .icon {
  color: #fff;
}

.service-item-three:hover .title a,
.service-item-three:hover p {
  color: #fff;
}

.service-item-three:hover::before {
  opacity: 1;
  visibility: visible;
}

.service-item-three:hover .service-link {
  background-color: #fff;
  color: #0c59db;
}

.service-item-four .services-thumb {
  margin-bottom: 45px;
  position: relative;
  z-index: 2;
}

.service-item-four .services-thumb img {
  width: 100%;
}

.service-item-four .icon {
  width: 90px;
  height: 90px;
  background: #fff;
  -webkit-filter: drop-shadow(0px 3px 30px rgba(0, 0, 0, 0.07));
  filter: drop-shadow(0px 3px 30px rgba(0, 0, 0, 0.07));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-top: -90px;
  position: relative;
  z-index: 5;
}

.service-item-four .icon img {
  max-width: 55px;
  max-width: 55px;
}

.service-item-four .services-content .title {
  font-size: 26px;
  color: #232323;
  border-bottom: 2px #e3eeff solid;
  padding-bottom: 25px;
  margin-bottom: 30px;
  position: relative;
}

@media (max-width: 575px) {
  .service-item-four .services-content .title {
    font-size: 22px;
  }
}

.service-item-four .services-content .title::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 3px;
  background: #0c59db;
  left: 0;
  bottom: -3px;
}

.service-item-four .services-content .service-link {
  font-weight: 700;
  color: #232323;
  line-height: 50px;
  padding: 0 30px;
  border: 3px solid #0c59db;
  border-radius: 5px;
  margin-top: 37px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.service-item-four .services-content .service-link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
  background-color: #0c59db;
}

.service-item-four .services-content .service-link i {
  position: relative;
  top: 2px;
  margin-left: 5px;
}

.service-item-four .services-content .service-link:hover {
  color: #fff;
  border-color: #0c59db;
}

.service-item-four .services-content .service-link:hover::before {
  width: 100%;
}

.service-item-four.no-border .services-content .title {
  border: none;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.service-item-four.no-border .services-content .title::before {
  display: none;
}

.service-item-five a {
  position: relative;
  display: block;
  background-color: #fff;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 109, 232, 0.04);
  box-shadow: 0px 8px 16px 0px rgba(0, 109, 232, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 2;
  font-size: 20px;
  line-height: 1.3;
  font-family: "Alegreya Sans";
  font-weight: 700;
  color: #002249;
  padding: 35px 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-item-five a {
    padding: 30px;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .service-item-five a {
    padding: 30px;
    font-size: 18px;
  }
}

.service-item-five a i {
  color: #81a3bb;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-item-five a i {
    right: 20px;
  }
}

.service-item-five a::before {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Brands";
  font-size: 80px;
  line-height: 1;
  top: 5px;
  left: 5px;
  color: #fff;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-weight: 300;
}

.service-item-five a:hover {
  background: #0c59db;
}

.service-item-five a:hover, .service-item-five a:hover i {
  color: #fff;
}

.service-item-five a:hover::before {
  opacity: 0.1;
  visibility: visible;
}

.service-item-six {
  color: #81a3bb;
  border: 2px solid #e3eeff;
  padding: 75px 33px;
  position: relative;
  overflow: hidden;
}

.service-item-six .icon {
  font-size: 80px;
  line-height: 1;
  color: #0c59db;
  margin-bottom: 30px;
}

.service-item-six .title {
  font-size: 36px;
  margin-bottom: 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-item-six .title {
    font-size: 26px;
  }
}

@media (max-width: 991px) {
  .service-item-six .title {
    font-size: 32px;
  }
}

.service-item-six .service-link {
  font-weight: 700;
  text-transform: capitalize;
  color: #81a3bb;
  margin-top: 15px;
}

.service-item-six .service-link:hover {
  color: #0c59db;
}

.service-item-six .count {
  position: absolute;
  font-size: 200px;
  color: #f5f6f7;
  right: 18px;
  bottom: 0;
  font-family: "Alegreya Sans";
  font-weight: 700;
  z-index: -1;
  line-height: 1;
}

@media (max-width: 1199px) {
  .service-item-six .count {
    font-size: 160px;
  }
}

.service-item-seven {
  color: #81a3bb;
  border: 15px solid #f5faff;
  padding: 45px 35px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .service-item-seven {
    padding: 40px 30px;
    border-width: 10px;
  }
}

.service-item-seven .icon {
  font-size: 80px;
  line-height: 1;
  color: #0c59db;
  margin-bottom: 30px;
}

.service-item-seven .title {
  font-size: 36px;
  margin-bottom: 20px;
}

@media (max-width: 1199px) {
  .service-item-seven .title {
    font-size: 28px;
  }
}

.service-item-seven .service-link {
  font-weight: 700;
  text-transform: capitalize;
  color: #81a3bb;
  margin-top: 15px;
}

.service-item-seven .service-link:hover {
  color: #0c59db;
}

.service-item-eight {
  position: relative;
  overflow: hidden;
}

.service-item-eight .service-img img {
  width: 100%;
}

.service-item-eight .services-overlay {
  position: absolute;
  bottom: -110px;
  left: 0;
  width: 100%;
  height: 180px;
  background-color: rgba(0, 34, 73, 0.7);
  padding: 20px 40px 40px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-item-eight .services-overlay {
    padding: 20px 20px 20px;
  }
}

@media (max-width: 1199px) {
  .service-item-eight .services-overlay {
    bottom: -115px;
  }
}

@media (max-width: 575px) {
  .service-item-eight .services-overlay {
    padding: 20px 20px 20px;
  }
}

.service-item-eight .services-overlay .icon {
  position: absolute;
  top: -20px;
  left: 20px;
  height: 70px;
  width: 70px;
  background: #0c59db;
  color: #fff;
  font-size: 30px;
  line-height: 1;
  text-align: center;
  line-height: 70px;
}

.service-item-eight .services-overlay p {
  color: #fff;
  font-size: 14px;
  padding-top: 20px;
}

.service-item-eight .services-overlay .title {
  padding-left: 80px;
  font-size: 20px;
}

@media (max-width: 1199px) {
  .service-item-eight .services-overlay .title {
    font-size: 18px;
  }
}

.service-item-eight .services-overlay .title a {
  color: #fff;
}

.service-item-eight:hover .services-overlay {
  bottom: 0;
  background-color: rgba(12, 89, 219, 0.9);
}

.service-item-eight-video {
  position: relative;
  overflow: hidden;
}

.service-item-eight-video .service-img img {
  width: 100%;
}

.service-item-eight-video .services-overlay {
  position: absolute;
  bottom: -102px;
  left: 0;
  width: 100%;
  height: 180px;
  background-color: rgba(0, 34, 73, 0.7);
  padding: 0px 40px 40px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-item-eight-video .services-overlay {
    padding: 10px 20px 20px;
  }
}

@media (max-width: 1199px) {
  .service-item-eight-video .services-overlay {
    bottom: -115px;
  }
}

@media (max-width: 575px) {
  .service-item-eight-video-eight .services-overlay {
    padding: 10px 20px 20px;
  }
}

.service-item-eight-video .services-overlay .icon {
  position: absolute;
  top: -20px;
  left: 20px;
  height: 70px;
  width: 70px;
  background: #0c59db;
  color: #fff;
  font-size: 30px;
  line-height: 1;
  text-align: center;
  line-height: 70px;
}

.service-item-eight-video .services-overlay p {
  color: #fff;
  font-size: 14px;
  padding-top: 20px;
}

.service-item-eight-video .services-overlay .title {
  padding-left: 40px;
  font-size: 20px;
}

@media (max-width: 1199px) {
  .service-item-eight-video .services-overlay .title {
    font-size: 18px;
  }
}

.service-item-eight-video .services-overlay .title a {
  color: #fff;
}

.service-item-eight-video:hover .services-overlay {
  bottom: 0;
  background-color: rgba(12, 89, 219, 0.9);
}

.service-section.service-with-shape {
  position: relative;
  margin-top: -235px;
  z-index: 22;
}

.service-section.service-with-shape::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #0c59db;
  height: 835px;
  z-index: -1;
}


@media (max-width: 991px) {
  .service-section.service-with-shape::before {
    left: 20px;
    right: 20px;
  }

  .service-section.service-with-shape {
    position: relative;
    margin-top: unset !important;
    z-index: 22;
  }
}

@media (max-width: 575px) {
  .service-section.service-with-shape::before {
    left: 0;
    right: 0;
    height: 100% !important;
  }
}

.service-section.service-with-shape .section-title {
  position: relative;
  z-index: 1;
}

.service-section.service-with-shape .section-title .title {
  font-size: 55px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-section.service-with-shape .section-title .title {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .service-section.service-with-shape .section-title .title {
    font-size: 46px;
  }
}

@media (max-width: 767px) {
  .service-section.service-with-shape .section-title .title {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .service-section.service-with-shape .section-title .title {
    font-size: 26px;
  }
}

.service-section.service-with-shape .section-title .ring-shape {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.1);
  top: -40px;
  right: 50px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-animation: scale 15s linear infinite;
  animation: scale 15s linear infinite;
  z-index: -1;
}

@media (max-width: 575px) {
  .service-section.service-with-shape .section-title .ring-shape {
    height: 40px;
    width: 40px;
  }
}

.service-section.service-with-shape .section-title::before {
  position: absolute;
  content: url(../img/icon/services-1.png);
/*  content: url(../img/shape/about-shape-02.png);*/
  height: 145px;
  width: 145px;
/*  border: 10px solid rgba(255, 255, 255, 0.1);*/
  top: -70px;
  left: 0;
  -webkit-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
  z-index: -1;
  -webkit-animation: rotate 30s linear infinite;
  animation: rotate 30s linear infinite;
}

@media (max-width: 991px) {
  .service-section.service-with-shape .section-title::before {
    height: 120px;
    width: 120px;
  }
}

@media (max-width: 575px) {
  .service-section.service-with-shape .section-title::before {
    display: none;
  }
}

.service-section.service-with-shape .section-title::after {
  position: absolute;
  content: url(../img/icon/services-2.png);
  height: 80px;
  width: 80px;
/*  border: 10px solid rgba(255, 255, 255, 0.1);*/
  bottom: -100px;
  right: 10px;
  -webkit-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
  z-index: -1;
  -webkit-animation: rotateTwo 30s linear infinite;
  animation: rotateTwo 30s linear infinite;
}

@media (max-width: 575px) {
  .service-section.service-with-shape .section-title::after {
    display: none;
  }
}

.service-section.service-with-shape-two {
  position: relative;
  z-index: 2;
}

.service-section.service-with-shape-two::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 500px;
  z-index: -1;
  background-image: url(../img/shape/service-bg.jpg);
  background-size: cover;
  background-position: center;
}

.service-section.service-with-shape-two .square-shape {
  position: absolute;
  content: "";
  height: 145px;
  width: 145px;
  border: 10px solid rgba(255, 255, 255, 0.1);
  top: 80px;
  left: 50px;
  -webkit-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
  z-index: -1;
  -webkit-animation: rotate 30s linear infinite;
  animation: rotate 30s linear infinite;
}

.service-section.service-with-shape-two .square-shape-two {
  position: absolute;
  content: "";
  height: 80px;
  width: 80px;
  border: 10px solid rgba(255, 255, 255, 0.1);
  right: 80px;
  top: 200px;
  -webkit-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
  z-index: -1;
  -webkit-animation: rotateTwo 30s linear infinite;
  animation: rotateTwo 30s linear infinite;
}

.service-section.service-with-shape-two .section-title {
  position: relative;
  z-index: 1;
}

.service-section.service-with-shape-two .section-title .title {
  font-size: 55px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-section.service-with-shape-two .section-title .title {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .service-section.service-with-shape-two .section-title .title {
    font-size: 46px;
  }
}

@media (max-width: 767px) {
  .service-section.service-with-shape-two .section-title .title {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .service-section.service-with-shape-two .section-title .title {
    font-size: 26px;
  }
}

.service-section.service-with-shape-two .section-title .ring-shape {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.1);
  top: -40px;
  right: 50px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-animation: scale 15s linear infinite;
  animation: scale 15s linear infinite;
  z-index: -1;
}

.service-details-text-block {
  padding-left: 50px;
}

@media (max-width: 991px) {
  .service-details-text-block {
    padding-left: 0;
  }
}

.service-details-text-block .service-support-btn {
  margin-top: 40px;
  background: #ff5f6e;
  text-align: center;
  padding: 20px 40px;
  position: relative;
  display: inline-block;
  z-index: 5;
}

.service-details-text-block .service-support-btn p {
  color: #fff;
  font-size: 14px;
}

.service-details-text-block .service-support-btn span {
  color: #fff;
  font-weight: 700;
  font-size: 23px;
}

.service-details-text-block .service-support-btn i {
  position: absolute;
  top: 8px;
  left: 10px;
  opacity: 0.05;
  color: #fff;
  font-size: 60px;
  z-index: -1;
}

.services-details-imgs .img-two img {
  margin-top: -170px;
  border: 14px solid #fff;
}

/*=======  Feature  =======*/
.feature-text-block p {
  font-size: 16px;
  color: #69a1bb;
}

.feature-text-block p.big-text {
  font-size: 26px;
}

@media (max-width: 575px) {
  .feature-text-block p.big-text {
    font-size: 18px;
    line-height: 1.4;
  }
}

.feature-text-block .feature-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .feature-text-block .feature-btn {
    display: block;
  }
}

.feature-text-block .feature-btn li:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 575px) {
  .feature-text-block .feature-btn li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.feature-text-block.with-absolute-image {
  border: 15px solid #f5faff;
  padding: 90px 85px 100px 535px;
  position: relative;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .feature-text-block.with-absolute-image {
    padding: 90px 30px 100px 320px;
  }
}

@media (max-width: 991px) {
  .feature-text-block.with-absolute-image {
    padding: 40px;
  }
}

@media (max-width: 575px) {
  .feature-text-block.with-absolute-image {
    padding: 30px;
    border-width: 8px;
  }
}

.feature-text-block.with-absolute-image .absolute-img {
  position: absolute;
  top: -15px;
  bottom: -15px;
  left: -15px;
  width: 465px;
  background-size: cover;
  background-position: center;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .feature-text-block.with-absolute-image .absolute-img {
    width: 300px;
  }
}

@media (max-width: 991px) {
  .feature-text-block.with-absolute-image .absolute-img {
    display: none;
  }
}

.feature-list li {
  padding: 0 80px;
  position: relative;
}

@media (max-width: 1199px) {
  .feature-list li {
    padding-right: 30px;
  }
}

@media (max-width: 575px) {
  .feature-list li {
    padding-right: 0;
  }
}

.feature-list li::before {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(160, 194, 255, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(160, 194, 255, 0.2);
  font-size: 20px;
  color: #0c59db;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: absolute;
  top: -10px;
  left: 0;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "";
}

.feature-list li:not(:last-child) {
  margin-bottom: 40px;
}

.feature-list li h4 {
  font-size: 24px;
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .feature-list li h4 {
    font-size: 18px;
  }
}

.feature-list li p {
  color: #81a3bb;
}

.feature-list li:hover::before {
  background-color: #0c59db;
  color: #fff;
}

.feature-box {
  position: relative;
}

.feature-box img {
  width: 100%;
}

.feature-box .feature-overlay {
  padding: 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 34, 73, 0.4);
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  overflow: hidden;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .feature-box .feature-overlay {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .feature-box .feature-overlay {
    padding: 25px;
  }
}

.feature-box .feature-overlay .title {
  color: #fff;
  font-size: 22px;
  padding-bottom: 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .feature-box .feature-overlay .title {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .feature-box .feature-overlay .title {
    font-size: 20px;
  }
}

.feature-box .feature-overlay p {
  color: #cadef6;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.feature-box .feature-overlay a {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
}

.feature-box .feature-overlay i {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 200px;
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
}

@media (max-width: 575px) {
  .feature-box .feature-overlay i {
    font-size: 120px;
  }
}

.feature-box .feature-overlay .content {
  margin-bottom: -25px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.feature-box .feature-overlay:hover {
  background-color: rgba(12, 89, 219, 0.9);
}

.feature-box .feature-overlay:hover .content {
  margin-bottom: 0;
}

.feature-box .feature-overlay:hover a {
  opacity: 1;
}

.feature-box .feature-overlay:hover i {
  opacity: 0.1;
}

.feature-intro-video {
  position: relative;
}

.feature-intro-video .video-popup {
  height: 100px;
  width: 100px;
  color: #fff;
  background: #0c59db;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 575px) {
  .feature-intro-video .video-popup {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

.feature-intro-video .video-popup::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnBefore 1.8s linear infinite;
  animation: pulseBtnBefore 1.8s linear infinite;
}

.feature-intro-video .video-popup::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnAfter 1.5s linear infinite;
  animation: pulseBtnAfter 1.5s linear infinite;
}

.features-sub {
  padding: 60px 60px 70px;
  background: #006de8;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .features-sub {
    padding: 50px 40px;
  }
}

@media (max-width: 575px) {
  .features-sub {
    padding: 50px 30px;
  }
}

.features-sub .title {
  text-transform: capitalize;
  color: #fff;
  font-size: 30px;
  margin-bottom: 8px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .features-sub .title {
    font-size: 24px;
  }
}

.features-sub p {
  color: #fff;
  padding-bottom: 25px;
}

.features-sub .input-box input {
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  padding-left: 30px;
  color: #fff;
  font-size: 14px;
  height: 65px;
}

.features-sub .input-box button {
  height: 65px;
  width: 100%;
  background: #005ec9;
  border: none;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}

/*=======  Portfolio  =======*/
.portfolio-area.portfolio-area-shape {
  position: relative;
  z-index: 1;
}

.portfolio-area.portfolio-area-shape::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/shape/portfolio-shape.png);
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.portfolio-item {
  position: relative;
  z-index: 1;
}

.portfolio-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(16, 16, 40, 0)), color-stop(37%, #101028), to(#101028));
  background: -o-linear-gradient(rgba(16, 16, 40, 0) 0%, #101028 37%, #101028 100%);
  background: linear-gradient(rgba(16, 16, 40, 0) 0%, #101028 37%, #101028 100%);
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}

.portfolio-item .portfolio-img img {
  width: 100%;
}

.portfolio-item .portfolio-content {
  position: absolute;
  left: 50px;
  bottom: 50px;
  right: 50px;
  z-index: 2;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-item .portfolio-content {
    left: 30px;
    right: 30px;
    bottom: 30px;
  }
}

@media (max-width: 767px) {
  .portfolio-item .portfolio-content {
    left: 30px;
    right: 30px;
    bottom: 30px;
  }
}

.portfolio-item .portfolio-content .category {
  color: #fff;
  font-weight: 700;
}

.portfolio-item .portfolio-content .title {
  font-size: 26px;
  color: #fff;
  line-height: 36px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-item .portfolio-content .title {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .portfolio-item .portfolio-content .title {
    font-size: 22px;
  }
}

.portfolio-item .portfolio-link {
  position: absolute;
  right: -50px;
  bottom: -50px;
  background-color: #fff;
  color: #0c59db;
  font-size: 20px;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 62px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-item .portfolio-link {
    bottom: -30px;
    right: -30px;
    height: 50px;
    width: 50px;
    line-height: 52px;
  }
}

@media (max-width: 767px) {
  .portfolio-item .portfolio-link {
    bottom: -30px;
    right: -30px;
    height: 50px;
    width: 50px;
    line-height: 52px;
  }
}

.portfolio-item:hover::before {
  opacity: 0.8;
  visibility: visible;
}

.portfolio-item:hover .portfolio-content {
  opacity: 1;
  visibility: visible;
}

.portfolio-item-two {
  color: #81a3bb;
}

.portfolio-item-two .portfolio-img {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 250px;
  background-color: #ddd;
}

.portfolio-item-two .portfolio-content {
  padding: 40px 40px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(60, 110, 203, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(60, 110, 203, 0.1);
  background-color: #fff;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-item-two .portfolio-content {
    padding: 30px 25px;
  }
}

@media (max-width: 575px) {
  .portfolio-item-two .portfolio-content {
    padding: 30px;
  }
}

.portfolio-item-two .portfolio-content .category {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #0c59db;
  letter-spacing: 2px;
  padding-bottom: 5px;
}

.portfolio-item-two .portfolio-content .title {
  font-size: 24px;
  margin-bottom: 15px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-item-two .portfolio-content .title {
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .portfolio-item-two .portfolio-content .title {
    font-size: 20px;
  }
}

.portfolio-item-two .portfolio-content .title a {
  color: #002249;
}

.portfolio-item-three {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.portfolio-item-three img {
  width: 100%;
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.portfolio-item-three::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #002249;
  opacity: 0.4;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.portfolio-item-three .post-content {
  position: absolute;
  left: 40px;
  bottom: 40px;
  right: 40px;
  z-index: 3;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  max-height: 95px;
  overflow: hidden;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-item-three .post-content {
    left: 25px;
    bottom: 30px;
    right: 25px;
  }
}

@media (max-width: 575px) {
  .portfolio-item-three .post-content {
    left: 25px;
    bottom: 30px;
    right: 25px;
  }
}

.portfolio-item-three .post-content .cat {
  font-weight: 700;
  color: #fff;
  font-size: 14px;
}

.portfolio-item-three .post-content .title {
  font-size: 24px;
  padding-top: 10px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-item-three .post-content .title {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .portfolio-item-three .post-content .title {
    font-size: 20px;
  }
}

.portfolio-item-three .post-content .title a {
  color: #fff;
}

.portfolio-item-three .portfolio-link {
  color: #fff;
  font-size: 30px;
  position: absolute;
  line-height: 1;
  bottom: 20px;
  left: 40px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
  z-index: 4;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-item-three .portfolio-link {
    left: 25px;
  }
}

@media (max-width: 575px) {
  .portfolio-item-three .portfolio-link {
    left: 25px;
  }
}

.portfolio-item-three:hover img {
  -webkit-transform: scale(1.2) rotate(5deg);
  -ms-transform: scale(1.2) rotate(5deg);
  transform: scale(1.2) rotate(5deg);
}

.portfolio-item-three:hover .post-content {
  bottom: calc( 100% - 130px );
}

.portfolio-item-three:hover::before {
  opacity: 0.9;
  background-color: #0c59db;
}

.portfolio-item-three:hover .portfolio-link {
  bottom: 40px;
  visibility: visible;
  opacity: 1;
}

.portfolio-arrow,
.portfolio-arrow-two {
  text-align: right;
}

.portfolio-arrow .slick-arrow,
.portfolio-arrow-two .slick-arrow {
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 2px solid #0c59db;
  cursor: pointer;
}

.portfolio-arrow .slick-arrow.next,
.portfolio-arrow-two .slick-arrow.next {
  background-color: #fff;
  color: #0c59db;
  margin-left: 15px;
  border-color: #fff;
}

.portfolio-arrow-two .slick-arrow {
  border-color: #ededed;
  color: #0c59db;
}

.portfolio-arrow-two .slick-arrow.next {
  background-color: #0c59db;
  color: #fff;
  margin-left: 15px;
  border-color: #fff;
}

.portfolio-slider-three .slick-arrow {
  position: absolute;
  top: 54%;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  z-index: 99;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #f4f8ff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: #0c59db;
}

.portfolio-slider-three .slick-arrow.prev {
  left: -70px;
}

.portfolio-slider-three .slick-arrow.next {
  right: -70px;
}

.portfolio-area.portfolio-mt-negative {
  margin-top: -511px;
  position: relative;
  z-index: 2;
  padding-bottom: 90px;
}

/*======= Portfolio Details =======*/
.case-details-area .case-details-thumb {
  position: relative;
  z-index: 1;
}

.case-details-area .case-live {
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 26, 55, 0.3);
  box-shadow: 0px 8px 16px 0px rgba(0, 26, 55, 0.3);
  position: absolute;
  background: #fff;
  top: 20px;
  right: 20px;
  padding: 45px 0px 45px 40px;
}

@media (max-width: 767px) {
  .case-details-area .case-live {
    left: 0;
    top: 0px;
    position: unset;
  }
}

@media (max-width: 575px) {
  .case-details-area .case-live .case-live-item-area {
    margin: 0;
  }
}

.case-details-area .case-live .case-live-item-area .case-live-item {
  padding-left: 30px;
  padding-right: 40px;
  position: relative;
}

@media (max-width: 575px) {
  .case-details-area .case-live .case-live-item-area .case-live-item {
    padding-bottom: 20px;
  }
}

.case-details-area .case-live .case-live-item-area .case-live-item .title {
  font-size: 20px;
}

.case-details-area .case-live .case-live-item-area .case-live-item span {
  font-size: 14px;
  color: #81a3bb;
}

.case-details-area .case-live .case-live-item-area .case-live-item i {
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 20px;
  color: #0c59db;
}

.case-details-area .case-live .case-live-btn {
  margin-right: 40px;
  margin-top: 37px;
}

.case-details-area .case-details-content .case-details-case {
  position: relative;
}

.case-details-area .case-details-content .case-details-case img {
  position: absolute;
  left: 0;
  top: 5px;
}

@media (max-width: 767px) {
  .case-details-area .case-details-content .case-details-case img {
    position: unset;
    margin-bottom: 20px;
  }
}

.case-details-area .case-details-content .case-details-case p {
  padding-left: 265px;
}

@media (max-width: 767px) {
  .case-details-area .case-details-content .case-details-case p {
    padding-left: 0;
  }
}

.case-details-area .case-content-2 .title {
  font-size: 24px;
  padding-top: 13px;
  padding-bottom: 14px;
}

.case-details-area .case-video-thumb {
  position: relative;
}

.case-details-area .case-video-thumb a {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  text-align: center;
  line-height: 120px;
  background: #0c59db;
  font-size: 24px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .case-details-area .case-video-thumb a {
    height: 80px;
    width: 80px;
    line-height: 80px;
  }
}

.case-details-area .case-video-thumb a::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #0c59db;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnBefore 1.8s linear infinite;
  animation: pulseBtnBefore 1.8s linear infinite;
}

.case-details-area .case-video-thumb a::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #0c59db;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnAfter 1.5s linear infinite;
  animation: pulseBtnAfter 1.5s linear infinite;
}

.case-details-area .case-next-prev {
  position: relative;
}

.case-details-area .case-next-prev .case-prev span {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #69a1bb;
}

.case-details-area .case-next-prev .case-prev .title {
  font-size: 28px;
}

@media (max-width: 767px) {
  .case-details-area .case-next-prev .case-prev .title {
    font-size: 20px;
  }
}

.case-details-area .case-next-prev .case-next span {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #69a1bb;
}

.case-details-area .case-next-prev .case-next .title {
  font-size: 28px;
}

@media (max-width: 767px) {
  .case-details-area .case-next-prev .case-next .title {
    font-size: 20px;
  }
}

/*=======  Counter  =======*/
.counter-box {
  text-align: center;
  padding: 35px;
  border: 3px solid rgba(255, 255, 255, 0.1);
}

.counter-box .icon {
  height: 75px;
  width: 75px;
  font-size: 30px;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #0c59db;
  border-radius: 50%;
  margin-bottom: 20px;
}

.counter-box .counter {
  display: block;
  line-height: 1;
  font-family: "Alegreya Sans";
  color: #fff;
  font-size: 70px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .counter-box .counter {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .counter-box .counter {
    font-size: 52px;
  }
}

.counter-box .title {
  color: #fff;
  font-family: "Alegreya Sans", sans-serif;
  margin-top: 15px;
  font-size: 18px;
  letter-spacing: -1px;
}

.counter-box.color-1 .icon {
  background-color: #00978e;
}

.counter-box.color-2 .icon {
  background-color: #fbb81b;
}

.counter-box.color-3 .icon {
  background-color: #ef452e;
}

.counter-box-two {
  border-radius: 5px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
  padding: 25px 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .counter-box-two {
    padding: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .counter-box-two {
    padding: 25px;
  }
}

@media (max-width: 575px) {
  .counter-box-two {
    padding: 40px;
    text-align: center;
  }
}

.counter-box-two .counter-wrap {
  font-size: 55px;
  line-height: 1;
  color: #fff;
  margin-bottom: 5px;
  font-family: "Alegreya Sans";
  font-weight: 700;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .counter-box-two .counter-wrap {
    font-size: 45px;
  }
}

@media (max-width: 767px) {
  .counter-box-two .counter-wrap {
    font-size: 45px;
  }
}

.counter-box-two .counter-wrap sup {
  font-family: "Alegreya Sans", sans-serif;
}

.counter-box-two .title {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .counter-box-two .title {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .counter-box-two .title {
    font-size: 16px;
  }
}

.counter-box-three .counter-wrap {
  font-size: 55px;
  font-weight: 500;
  color: #0c59db;
  font-family: "Alegreya Sans";
  line-height: 1;
}

.counter-box-three .title {
  font-size: 18px;
  color: #232323;
  display: block;
  font-weight: 700;
  margin: 15px 0 15px;
  line-height: 1.3;
}

.counter-box-three p {
  font-size: 15px;
  padding-right: 30px;
}

.counter-box-four {
  border: 2px solid #e3eeff;
  padding: 34px 38px;
  position: relative;
  z-index: 10;
  overflow: hidden;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .counter-box-four {
    padding: 30px 25px;
  }
}

.counter-box-four .counter-wrap {
  font-size: 50px;
  font-weight: 500;
  color: #0c59db;
  font-family: "Alegreya Sans";
  line-height: 1;
  position: relative;
  margin-bottom: 5px;
}

.counter-box-four .counter-wrap::after {
  content: "+";
  font-size: 16px;
  line-height: 1;
  right: 5px;
}

.counter-box-four .title {
  font-size: 14px;
  font-weight: 700;
}

.counter-box-four p {
  font-size: 14px;
  padding-top: 15px;
}

.counter-box-four i {
  font-size: 120px;
  position: absolute;
  top: 10px;
  right: 15px;
  color: #f6fbff;
  z-index: -1;
}

.counter-box-five {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.counter-box-five .icon {
  font-size: 50px;
  line-height: 1;
  color: #fff;
  margin-right: 30px;
}

.counter-box-five .counter {
  color: #fff;
  font-size: 50px;
  line-height: 1;
  font-weight: 700;
  font-family: "Alegreya Sans";
  margin-bottom: 5px;
}

.counter-box-five .counter::after {
  content: "+";
  font-size: 16px;
  margin-left: 5px;
}

.counter-box-five .title {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
  opacity: 0.6;
  font-family: "Alegreya Sans", sans-serif;
}

.piechart-box {
  height: 230px;
  width: 230px;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}

.piechart-box .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #0c59db;
  line-height: 1;
  font-size: 70px;
}

.piechart-box .counter {
  position: absolute;
  color: #f2f8ff;
  z-index: -1;
  font-size: 70px;
  bottom: 50px;
  width: 100%;
  text-align: center;
  line-height: 1;
  font-weight: 700;
  font-family: "Alegreya Sans";
}

.counter-section-two {
  background-size: cover;
  background-position: center;
  background-image: url(../img/video-bg.jpg);
  padding: 100px 0 50px;
}

.faq-counter-boxes-two.row {
  margin-left: -40px;
  margin-right: -40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .faq-counter-boxes-two.row {
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media (max-width: 767px) {
  .faq-counter-boxes-two.row {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.faq-counter-boxes-two.row > [class*=col-] {
  padding-right: 40px;
  padding-left: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .faq-counter-boxes-two.row > [class*=col-] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .faq-counter-boxes-two.row > [class*=col-] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.faq-counter-boxes-two .counter-box-two {
  margin-top: 80px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .faq-counter-boxes-two .counter-box-two {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .faq-counter-boxes-two .counter-box-two {
    margin-top: 40px;
  }
}

@media (max-width: 575px) {
  .faq-counter-boxes-two .counter-box-two {
    margin-top: 30px;
  }
}

.counter-faq-section-one {
  padding-top: 120px;
  padding-bottom: 250px;
  position: relative;
  z-index: 1;
}

.counter-faq-section-one .map-image {
  position: absolute;
  z-index: -1;
  right: 100px;
  top: 140px;
  opacity: 0.1;
}

/*=======  Team  =======*/
.team-member {
  text-align: center;
  -webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.06));
  filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.06));
}

.team-member .member-photo {
  position: relative;
  z-index: 1;
}

.team-member .member-photo img {
  border-radius: 5px;
}

.team-member .member-photo .social-icon {
  position: absolute;
  bottom: 0;
  z-index: 2;
  left: 0;
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
  text-align: center;
}

.team-member .member-photo .social-icon a {
  width: 36px;
  height: 36px;
  margin: 0px 5px;
  line-height: 36px;
  color: #0c59db;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  font-size: 15px;
}

.team-member .member-photo .social-icon a:hover {
  background-color: #0c59db;
  color: #fff;
}

.team-member:hover .social-icon {
  bottom: 20px;
  opacity: 1;
  visibility: visible;
}

.team-member .team-content {
  background-color: #fff;
  padding: 30px;
}

.team-member .team-content .name {
  font-size: 20px;
  margin-bottom: 5px;
}

.team-member .team-content .name a {
  color: #232323;
}

.team-member .team-content .name a:hover {
  color: #0c59db;
}

.team-member .team-content .position {
  line-height: 1.5;
}

.team-member-two {
  position: relative;
}

.team-member-two img {
  width: 100%;
  border-radius: 5px;
}

.team-member-two .team-content {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-radius: 6px;
  background: #0c59db;
  text-align: center;
  padding: 25px 0px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.team-member-two .team-content .social-icon {
  margin-bottom: 10px;
}

.team-member-two .team-content .social-icon a {
  font-size: 15px;
  color: #fff;
  margin: 0 7px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.team-member-two .team-content .name {
  font-size: 24px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

@media (max-width: 575px) {
  .team-member-two .team-content .name {
    font-size: 22px;
  }
}

.team-member-two .team-content .name a {
  color: #fff;
}

.team-member-two .team-content .position {
  font-size: 14px;
  color: #9cbef7;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 575px) {
  .team-member-two .team-content .position {
    letter-spacing: 2px;
  }
}

.team-member-two:hover .team-content {
  left: 20px;
  bottom: -20px;
  opacity: 1;
}

@media (max-width: 575px) {
  .team-member-two:hover .team-content {
    left: 15px;
  }
}

.team-member-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.team-member-three .member-inner {
  position: relative;
  padding-bottom: 60px;
}

.team-member-three img {
  border: 10px solid #fff;
}

.team-member-three .team-content {
  position: absolute;
  right: 0;
  bottom: 0px;
  width: 90%;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 23px;
  padding-left: 40px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
}

.team-member-three .name {
  font-size: 24px;
}

.team-member-three .name a {
  color: #002249;
}

.team-member-three .position {
  color: #0c59db;
  font-weight: 700;
  padding-top: 9px;
}

.team-member-four .member-photo {
  position: relative;
  margin-bottom: 35px;
}

.team-member-four .member-photo::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: 5;
  border-left: 5px solid #0c59db;
  border-bottom: 5px solid #0c59db;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-member-four .member-photo img {
  border: 15px solid #fff;
  border-radius: 50%;
  width: 100%;
}

.team-member-four:hover .member-photo::before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.team-member-four .member-content .title {
  font-size: 24px;
}

.team-member-four .member-content .title a {
  color: #002249;
}

.team-member-four .member-content .position {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #0c59db;
  padding-top: 6px;
  padding-bottom: 15px;
}

.team-slider-two-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: -155px;
  position: relative;
  z-index: 2;
  margin-top: 100px;
}

.team-slider-two-arrow .slick-arrow {
  height: 80px;
  width: 80px;
  background: #fff;
  text-align: center;
  line-height: 80px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
  cursor: pointer;
  font-size: 20px;
  position: relative;
}

.team-slider-two-arrow .slick-arrow.next::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 50px;
  left: -1px;
  bottom: -12%;
  background: #e9f1ff;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.team-area.team-section-extra-padding {
  padding-top: 120px;
  padding-bottom: 300px;
}

.team-area.team-with-shape-two {
  position: relative;
  z-index: 2;
}

.team-area.team-with-shape-two::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 480px;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-image: url(../img/shape/team-bg.jpg);
}

/*=======  Team Join  =======*/
.team-join {
  margin-top: -180px;
  position: relative;
  z-index: 99;
}

.team-join .join-bg {
  background: #dcecff;
  border-radius: 30px;
  padding: 120px 160px 0;
}

@media (max-width: 1199px) {
  .team-join .join-bg {
    padding: 120px 100px 0;
  }
}

@media (max-width: 991px) {
  .team-join .join-bg {
    padding: 120px 60px 0;
  }
}

@media (max-width: 767px) {
  .team-join .join-bg {
    padding: 100px 60px 0;
  }
}

@media (max-width: 575px) {
  .team-join .join-bg {
    padding: 80px 20px;
  }
}

.team-join .join-bg .team-join-title span {
  font-size: 14px;
  font-weight: 700;
  color: #0c59db;
  position: relative;
}

.team-join .join-bg .team-join-title span::before {
  position: absolute;
  content: "";
  right: -100px;
  top: 10px;
  height: 2px;
  width: 70px;
  background: #0c59db;
}

.team-join .join-bg .team-join-title .title {
  font-size: 60px;
  padding-top: 20px;
}

@media (max-width: 1199px) {
  .team-join .join-bg .team-join-title .title {
    font-size: 46px;
  }
}

@media (max-width: 767px) {
  .team-join .join-bg .team-join-title .title {
    font-size: 46px;
  }
}

@media (max-width: 575px) {
  .team-join .join-bg .team-join-title .title {
    font-size: 32px;
  }
}

.team-join .join-bg .team-join-thumb {
  margin-bottom: -6px;
}

/*=======  Team Details  =======*/
.team-details-content {
  padding-left: 50px;
}

@media (max-width: 1199px) {
  .team-details-content {
    padding-left: 0;
  }
}

.team-details-content .title {
  font-size: 34px;
  padding-bottom: 5px;
}

@media (max-width: 575px) {
  .team-details-content .title {
    font-size: 24px;
  }
}

.team-details-content span {
  font-weight: 700;
  color: #0c59db;
  padding-bottom: 24px;
}

.team-details-content p {
  color: #81a3bb;
}

.team-details-content ul {
  margin-top: 35px;
}

.team-details-content ul li {
  display: inline-block;
}

.team-details-content ul a {
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  background: #f5faff;
  color: #81a3bb;
  margin-right: 8px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.team-details-content ul a:hover {
  background-color: #0c59db;
  color: #fff;
}

@media (max-width: 575px) {
  .team-details-content ul a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 12px;
  }
}

/*=======  Testimonials  =======*/
.testimonial-box p {
  padding: 45px 35px;
  border-top: 3px solid #0c59db;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
}

@media (max-width: 575px) {
  .testimonial-box p {
    padding: 30px;
  }
}

.testimonial-box .author {
  margin-top: 30px;
  padding-left: 20px;
}

.testimonial-box .author .desc {
  margin-left: 10px;
}

.testimonial-box .author .desc h6 {
  font-size: 16px;
  color: #232323;
  margin-bottom: 10px;
}

.testimonial-box.color-1 p {
  border-color: #006de8;
}

.testimonial-box.color-2 p {
  border-color: #00978e;
}

.testimonial-box.color-3 p {
  border-color: #ef452e;
}

.testimonial-box-two {
  padding-top: 35px;
}

.testimonial-box-two .testimonial-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 10px;
  background: #fff;
  -webkit-box-shadow: 0px 10px 30px rgba(239, 239, 239, 0.79);
  box-shadow: 0px 10px 30px rgba(239, 239, 239, 0.79);
  padding: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box-two .testimonial-inner {
    padding: 20px;
  }
}

@media (max-width: 575px) {
  .testimonial-box-two .testimonial-inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.testimonial-box-two .testimonial-inner .testimonial-img {
  position: relative;
  z-index: 1;
  width: 210px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 210px;
  flex: 0 0 210px;
  margin-top: -60px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box-two .testimonial-inner .testimonial-img {
    width: 180px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 180px;
    flex: 0 0 180px;
  }
}

@media (max-width: 575px) {
  .testimonial-box-two .testimonial-inner .testimonial-img {
    width: 250px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

.testimonial-box-two .testimonial-inner .testimonial-img i {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 57px;
  width: 52px;
  background-color: #0c59db;
  color: #fff;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial-box-two .testimonial-inner .content {
  padding-left: 30px;
  padding-bottom: 10px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box-two .testimonial-inner .content {
    padding-left: 20px;
  }
}

@media (max-width: 575px) {
  .testimonial-box-two .testimonial-inner .content {
    padding-left: 0;
    padding-top: 30px;
  }
}

.testimonial-box-two .testimonial-inner .content p {
  margin-bottom: 20px;
}

.testimonial-box-two .testimonial-inner .content .name {
  font-size: 16px;
  font-family: "Alegreya Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.testimonials-arrow {
  text-align: right;
}

.testimonials-arrow .slick-arrow {
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid rgba(12, 89, 219, 0.1);
  cursor: pointer;
  color: #0c59db;
}

.testimonials-arrow .slick-arrow.next {
  background-color: #0c59db;
  margin-left: 15px;
  border-color: #0c59db;
  color: #fff;
}

/*=======  Call to action  =======*/
.call-to-action {
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.call-to-action::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #0c59db;
  opacity: 0.8;
}

.video-cta {
  background-size: cover;
  background-position: center;
  z-index: 1;
  position: relative;
  padding-top: 120px;
  padding-bottom: 150px;
}

.video-cta::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(16, 16, 40, 0.8);
}

.video-cta .video-cta-content {
  border: 10px solid rgba(255, 255, 255, 0.15);
  padding: 40px 70px 50px;
  margin-right: 70px;
}

@media (max-width: 1199px) {
  .video-cta .video-cta-content {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .video-cta .video-cta-content {
    padding: 40px 50px 190px;
  }

  .video-cta{
    padding-bottom: 75px;
  }
}

.video-cta .video-cta-content .title {
  font-size: 55px;
  color: #fff;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .video-cta .video-cta-content .title {
    font-size: 48px;
  }
}

@media (max-width: 767px) {
  .video-cta .video-cta-content .title {
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .video-cta .video-cta-content .title {
    font-size: 28px;
  }
}

.video-cta .video-cta-content p {
  color: #fff;
}

@media (max-width: 991px) {
  .video-cta .video-cta-play {
    margin-top: -150px;
    padding-left: 50px;
  }
}

.video-cta .video-cta-play .video-popup {
  height: 80px;
  width: 80px;
  background: #fff;
  color: #0c59db;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.video-cta .video-cta-play .video-popup::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #fff;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnBefore 1.8s linear infinite;
  animation: pulseBtnBefore 1.8s linear infinite;
}

.video-cta .video-cta-play .video-popup::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #fff;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnAfter 1.5s linear infinite;
  animation: pulseBtnAfter 1.5s linear infinite;
}

.call-to-action-two .call-to-action-inner {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: left center;
  padding: 90px 100px;
}

@media (max-width: 1199px) {
  .call-to-action-two .call-to-action-inner {
    padding: 90px 80px;
  }
}

@media (max-width: 767px) {
  .call-to-action-two .call-to-action-inner {
    padding: 60px;
  }
}

@media (max-width: 575px) {
  .call-to-action-two .call-to-action-inner {
    padding: 40px;
  }
}

.call-to-action-two .call-to-action-inner .title {
  font-size: 50px;
  color: #fff;
}

@media (max-width: 767px) {
  .call-to-action-two .call-to-action-inner .title {
    font-size: 38px;
  }
}

@media (max-width: 575px) {
  .call-to-action-two .call-to-action-inner .title {
    font-size: 32px;
  }
}

.call-to-action-two.cta-mt-negative {
  margin-top: -150px;
  position: relative;
  z-index: 2;
}

.call-to-action-three {
  padding-right: 30px;
  padding-left: 30px;
}

.call-to-action-three .call-to-action-inner {
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  padding: 90px 0;
  background-color: #002249;
  background-image: url(../img/cta-bg/cta-bg-3.png);
}

.call-to-action-three.cta-mt-negative {
  position: relative;
  z-index: 22;
  margin-top: -180px;
}

.call-to-action-four {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.call-to-action-four::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #0c59db;
  opacity: 0.9;
}

.call-to-action-four .cta-content .title-tag {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.call-to-action-four .cta-content .title {
  color: #fff;
  font-size: 60px;
}

@media (max-width: 767px) {
  .call-to-action-four .cta-content .title {
    font-size: 46px;
  }
}

.call-to-action-four .cta-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: -15px;
}

@media (max-width: 991px) {
  .call-to-action-four .cta-btns {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 40px;
  }
}

@media (max-width: 575px) {
  .call-to-action-four .cta-btns {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.call-to-action-four .cta-btns .cta-btn {
  background: #ff5f6e;
  text-align: center;
  padding: 20px 45px;
  position: relative;
  z-index: 5;
  margin: 15px;
}

.call-to-action-four .cta-btns .cta-btn i {
  position: absolute;
  top: 8px;
  left: 10px;
  opacity: 0.05;
  color: #fff;
  font-size: 60px;
  z-index: -1;
}

.call-to-action-four .cta-btns .cta-btn p {
  color: #fff;
  font-size: 14px;
}

.call-to-action-four .cta-btns .cta-btn span {
  color: #fff;
  font-weight: 700;
  font-size: 22px;
}

@media (max-width: 1199px) {
  .call-to-action-four .cta-btns .cta-btn span {
    font-size: 18px;
  }
}

.call-to-action-four .cta-btns .cta-btn.cta-btn-2 {
  background: #0c59db;
}

/*=======  Latest News  =======*/
.latest-news-box {
  -webkit-box-shadow: 0px 16px 32px rgba(228, 228, 228, 0.56);
  box-shadow: 0px 16px 32px rgba(228, 228, 228, 0.56);
}

.latest-news-box .post-content {
  padding: 25px 25px 30px;
  background-color: #fff;
}

.latest-news-box .post-content .title {
  font-size: 24px;
  line-height: 1.3;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .latest-news-box .post-content .title {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .latest-news-box .post-content .title {
    font-size: 20px;
  }
}

.latest-news-box .post-content .title a {
  color: #232323;
}

.latest-news-box .post-content .post-meta {
  margin-bottom: 10px;
}

.latest-news-box .post-content .post-meta li {
  display: inline-block;
}

.latest-news-box .post-content .post-meta a {
  font-size: 14px;
  font-weight: 700;
}

.latest-news-box .post-content .read-more-btn {
  margin-top: 25px;
  color: #232323;
  font-size: 15px;
  font-weight: 700;
}

.latest-news-box .post-content .read-more-btn i {
  margin-left: 10px;
}

.latest-news-box .post-content .read-more-btn:hover {
  color: #0c59db;
}

.latest-news-box-two .post-meta {
  margin-bottom: 10px;
}

.latest-news-box-two .post-meta li {
  display: inline-block;
}

.latest-news-box-two .post-meta a {
  font-size: 14px;
  font-weight: 700;
}

.latest-news-box-two .title {
  font-size: 24px;
  padding-bottom: 25px;
  line-height: 1.3;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .latest-news-box-two .title {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .latest-news-box-two .title {
    font-size: 20px;
  }
}

.latest-news-box-two .title a {
  color: #232323;
}

.latest-news-box-two .news-btn {
  font-size: 15px;
  color: #232323;
  background-color: transparent;
  border: 1px solid #0c59db;
  margin-top: 30px;
  padding: 20px 35px;
  line-height: 1;
  border-radius: 5px;
  font-weight: 700;
}

.latest-news-box-two .news-btn i {
  position: relative;
  top: 2px;
  margin-left: 5px;
}

.latest-news-box-two .news-btn:hover {
  background-color: #0c59db;
  border-color: #0c59db;
  color: #fff;
}

.latest-news-box-three {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.latest-news-box-three img {
  width: 100%;
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.latest-news-box-three::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #002249;
  opacity: 0.4;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.latest-news-box-three .post-content {
  position: absolute;
  left: 40px;
  bottom: 40px;
  right: 40px;
  z-index: 3;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  max-height: 95px;
  overflow: hidden;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .latest-news-box-three .post-content {
    left: 25px;
    bottom: 30px;
    right: 25px;
  }
}

@media (max-width: 575px) {
  .latest-news-box-three .post-content {
    left: 25px;
    bottom: 30px;
    right: 25px;
  }
}

.latest-news-box-three .post-content .cat {
  font-weight: 700;
  color: #fff;
  font-size: 14px;
}

.latest-news-box-three .post-content .title {
  font-size: 24px;
  padding-top: 10px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .latest-news-box-three .post-content .title {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .latest-news-box-three .post-content .title {
    font-size: 20px;
  }
}

.latest-news-box-three .post-content .title a {
  color: #fff;
}

.latest-news-box-three .news-link {
  color: #fff;
  font-size: 30px;
  position: absolute;
  line-height: 1;
  bottom: 20px;
  left: 40px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
  z-index: 4;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .latest-news-box-three .news-link {
    left: 25px;
  }
}

@media (max-width: 575px) {
  .latest-news-box-three .news-link {
    left: 25px;
  }
}

.latest-news-box-three:hover img {
  -webkit-transform: scale(1.2) rotate(5deg);
  -ms-transform: scale(1.2) rotate(5deg);
  transform: scale(1.2) rotate(5deg);
}

.latest-news-box-three:hover .post-content {
  bottom: calc( 100% - 130px );
}

.latest-news-box-three:hover::before {
  opacity: 0.9;
  background-color: #0c59db;
}

.latest-news-box-three:hover .news-link {
  bottom: 40px;
  visibility: visible;
  opacity: 1;
}

.latest-news-box-four {
  border: 10px solid #e3eeff;
  padding: 40px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .latest-news-box-four {
    padding: 30px;
    border-width: 8px;
  }
}

.latest-news-box-four .meta li {
  display: inline-block;
  font-size: 14px;
  padding-right: 15px;
  padding-bottom: 5px;
  color: #81a3bb;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .latest-news-box-four .meta li {
    padding-right: 8px;
  }
}

.latest-news-box-four .meta li i {
  padding-right: 5px;
  color: #0c59db;
}

.latest-news-box-four .title {
  font-size: 22px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.latest-news-box-four .title a {
  color: #232323;
}

.latest-news-box-four p {
  font-size: 14px;
  color: #81a3bb;
}

.latest-news-box-four .news-link {
  color: #81a3bb;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-top: 10px;
}

.latest-news-box-four .news-link i {
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.latest-news-box-four:hover {
  border-color: #0c59db;
}

.latest-news-box-four:hover .news-link {
  color: #0c59db;
}

.latest-news-white-box {
  padding-bottom: 80px;
  padding-top: 50px;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(228, 228, 228, 0.56);
  box-shadow: 0px 16px 32px 0px rgba(228, 228, 228, 0.56);
  background-color: #fff;
  margin-right: 40px;
  margin-left: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .latest-news-white-box {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 991px) {
  .latest-news-white-box {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 575px) {
  .latest-news-white-box {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/*=======  About  =======*/
.about-text-block .section-title .title {
  color: #232323;
  font-size: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .about-text-block .section-title .title {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .about-text-block .section-title .title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .about-text-block .section-title .title {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .about-text-block .section-title .title {
    font-size: 26px;
  }
}

.about-text-block .about-experience {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-text-block .about-experience h3 {
  font-weight: 700;
  color: #0c59db;
  font-size: 100px;
  padding-right: 15px;
  position: relative;
  line-height: 1;
}

@media (max-width: 575px) {
  .about-text-block .about-experience h3 {
    font-size: 60px;
  }
}

.about-text-block .about-experience h3::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 30px solid #0c59db;
  border-right: 30px solid transparent;
  left: -48px;
  top: 23px;
}

@media (max-width: 767px) {
  .about-text-block .about-experience h3::before {
    display: none;
  }
}

.about-text-block .about-experience span {
  font-size: 30px;
  font-family: "Alegreya Sans";
  font-weight: 700;
  color: #002249;
  text-transform: capitalize;
  line-height: 1.1;
}

@media (max-width: 767px) {
  .about-text-block .about-experience span {
    font-size: 30px;
  }
}

@media (max-width: 575px) {
  .about-text-block .about-experience span {
    font-size: 22px;
  }
}

.about-text-block .about-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .about-text-block .about-btns {
    display: block;
  }
}

.about-text-block .about-btns li:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 575px) {
  .about-text-block .about-btns li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.about-thumb {
  position: relative;
  z-index: 5;
}

.about-thumb::before {
  position: absolute;
  content: "";
  height: 630px;
  width: 630px;
  border: 120px solid #0c59db;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #fff;
  z-index: -1;
  left: -200px;
  top: 40px;
}

@media (max-width: 1199px) {
  .about-thumb::before {
    height: 500px;
    width: 500px;
    left: -100px;
    border-width: 80px;
  }
}

@media (max-width: 991px) {
  .about-thumb::before {
    top: 85px;
  }
}

@media (max-width: 575px) {
  .about-thumb::before {
    display: none;
  }
}

.about-section.about-with-shape {
  position: relative;
  z-index: 2;
}

.about-section.about-with-shape .about-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 575px) {
  .about-section.about-with-shape .about-shape-1 {
    width: 40%;
  }
}

.about-section.about-with-shape .about-shape-2 {
  position: absolute;
  top: 2em;
  right: 0;
  z-index: -1;
}

@media (max-width: 575px) {
  .about-section.about-with-shape .about-shape-2 {
    display: none;
  }
}

.history-timeline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-left: -60px;
  margin-right: -60px;
  padding: 30px 0;
}

.history-timeline::before {
  position: absolute;
  content: "";
  top: 0;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 2px;
  background: #e3eeff;
}

.history-timeline::after {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 60px;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #0c59db;
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "";
  font-size: 20px;
}

.history-timeline .history-item {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  margin-bottom: 80px;
  padding-left: 60px;
  padding-right: 60px;
}

.history-timeline .history-item .number-box {
  height: 60px;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 4px solid #9edbff;
  background-color: #fff;
  color: #616161;
  z-index: 5;
  position: absolute;
  top: -30px;
  right: -30px;
}

.history-timeline .history-item:nth-child(2) {
  margin-top: 300px;
}

.history-timeline .history-item:nth-child(even) .number-box {
  right: auto;
  left: -30px;
}

.history-timeline .history-item:nth-last-child(1), .history-timeline .history-item:nth-last-child(2) {
  margin-bottom: 0;
}

.history-timeline .history-item .history-thumb {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}

.history-timeline .history-item .history-content {
  padding: 35px 40px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
}

.history-timeline .history-item .history-content .history-year {
  color: #0c59db;
  font-weight: 700;
}

.history-timeline .history-item .history-content .title {
  font-size: 26px;
  padding-top: 9px;
}

/*===== Why Choose css =====*/
.why-choose-area {
  background-color: #101028;
  padding-top: 113px;
  padding-bottom: 113px;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.why-choose-area .single-choose .icon-box {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 175px;
  width: 175px;
  border-radius: 50%;
  border: 2px solid #252543;
  background: -o-radial-gradient(circle, #8383f9 0%, rgba(209, 209, 223, 0) 50%, rgba(255, 255, 255, 0) 74%);
  background: radial-gradient(circle, #8383f9 0%, rgba(209, 209, 223, 0) 50%, rgba(255, 255, 255, 0) 74%);
  position: relative;
  z-index: 2;
  font-size: 60px;
  color: #fff;
  line-height: 1;
  margin-bottom: 50px;
}

.why-choose-area .single-choose .icon-box .rotate-dot {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  z-index: -1;
  -webkit-animation: rotateTwo 30s linear infinite;
  animation: rotateTwo 30s linear infinite;
}

.why-choose-area .single-choose .icon-box .rotate-dot::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  top: -8px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
}

.why-choose-area .single-choose .icon-box .rotate-dot::after {
  position: absolute;
  content: "";
  height: 7px;
  width: 7px;
  background: #fff;
  bottom: -4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
}

.why-choose-area .single-choose h4 {
  font-size: 26px;
  color: #fff;
  margin-bottom: 20px;
}

.why-choose-area .single-choose p {
  color: #c7c7ff;
  padding: 0 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .why-choose-area .single-choose p {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .why-choose-area .single-choose p {
    padding: 0;
  }
}

.why-choose-area .choose-dot {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.why-choose-area .choose-dot img {
  width: 100%;
}

.why-choose-area .choose-shape {
  position: absolute;
  left: 0;
  top: -170px;
  z-index: -1;
}

.why-choose-area .choose-shape img {
  width: 100%;
}

/*=======  Contact  =======*/
.conatct-section {
  position: relative;
  z-index: 2;
}

.conatct-section .contact-form-area {
  background: #fff;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(228, 228, 228, 0.56);
  box-shadow: 0px 16px 32px 0px rgba(228, 228, 228, 0.56);
  padding: 60px;
}

@media (max-width: 575px) {
  .conatct-section .contact-form-area {
    padding: 30px;
  }
}

.conatct-section .contact-form-area .contact-form {
  padding-left: 10px;
}

.conatct-section .contact-form-area .contact-form .form-title {
  font-size: 40px;
  color: #232323;
  padding-right: 40px;
}

@media (max-width: 767px) {
  .conatct-section .contact-form-area .contact-form .form-title {
    font-size: 28px;
  }
}

.conatct-section .contact-form-area .contact-form .input-group {
  position: relative;
}

.conatct-section .contact-form-area .contact-form .input-group input,
.conatct-section .contact-form-area .contact-form .input-group textarea {
  height: 50px;
  border: 0;
  border-bottom: 2px solid #e3eeff;
  color: #616161;
  text-transform: capitalize;
  padding: 0;
}

.conatct-section .contact-form-area .contact-form .input-group textarea {
  height: 90px;
}

.conatct-section .contact-form-area .contact-form .input-group .icon {
  right: 0;
  color: #0c59db;
}

.conatct-section .conatct-one-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 74%;
  z-index: -2;
  background-size: cover;
  background-position: center;
  background-color: #0c59db;
  background-repeat: no-repeat;
}

.conatct-section .conatct-one-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #0c59db;
  opacity: 0.9;
}

.conatct-section-two {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.conatct-section-two::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #002249;
  opacity: 0.8;
}

.conatct-section-two .contact-form input,
.conatct-section-two .contact-form textarea {
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  color: #fff;
}

.conatct-section-two .contact-form ::-webkit-input-placeholder {
  color: #c1d0de;
}

.conatct-section-two .contact-form ::-moz-placeholder {
  color: #c1d0de;
}

.conatct-section-two .contact-form :-ms-input-placeholder {
  color: #c1d0de;
}

.conatct-section-two .contact-form ::-ms-input-placeholder {
  color: #c1d0de;
}

.conatct-section-two .contact-form ::placeholder {
  color: #c1d0de;
}

.contact-map-one {
  height: 340px;
}

.contact-map-one iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.contact-map-two {
  text-align: end;
  position: relative;
  z-index: 2;
}

.contact-map-two::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-bottom: 400px solid #0c59db;
  border-left: 400px solid transparent;
  right: -15px;
  bottom: -15px;
  z-index: -1;
}

@media (max-width: 575px) {
  .contact-map-two::before {
    border-width: 200px;
  }
}

.contact-map-two iframe {
  border: none;
  width: 90%;
  height: 560px;
}

@media (max-width: 991px) {
  .contact-map-two iframe {
    width: 100%;
  }
}

.contact-map-three iframe {
  border: none;
  width: 100%;
  height: 550px;
}

.contact-info-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #ededed;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.contact-info-items .contact-info-item {
  border: 1px solid #ededed;
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  padding: 55px 0 50px;
}

@media (max-width: 575px) {
  .contact-info-items .contact-info-item {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

.contact-info-items .contact-info-item i {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #0c59db;
  text-align: center;
  line-height: 80px;
  color: #fff;
  font-size: 24px;
}

.contact-info-items .contact-info-item .title {
  font-size: 24px;
  padding-top: 25px;
}

.contact-info-items .contact-info-item p {
  font-size: 18px;
  color: #69a1bb;
  padding-top: 5px;
}

.contact-video {
  position: relative;
  z-index: 1;
  text-align: right;
}

.contact-video::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-bottom: 400px solid #0c59db;
  border-left: 400px solid transparent;
  right: -20px;
  bottom: -20px;
  z-index: -1;
}

@media (max-width: 575px) {
  .contact-video::before {
    border-width: 200px;
  }
}

.contact-video .video-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 120px;
  width: 120px;
  text-align: center;
  line-height: 120px;
  color: #fff;
  font-size: 24px;
  z-index: 5;
  border-radius: 50%;
  background-color: #0c59db;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 575px) {
  .contact-video .video-popup {
    height: 80px;
    width: 80px;
    line-height: 80px;
  }
}

.contact-video .video-popup::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #fff;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0.6;
  -webkit-animation: pulseBtnBefore 1.5s linear infinite;
  animation: pulseBtnBefore 1.5s linear infinite;
}

.contact-video .video-popup::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #0c59db;
  border-radius: 50%;
  z-index: -1;
}

/*=======  Intro Video  =======*/
.intro-video-area {
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  padding-top: 110px;
  padding-bottom: 145px;
}

.intro-video-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #00152de6;
  opacity: 0.9;
}

.intro-video-area .intro-video-content span {
  color: #0c59db;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
}

.intro-video-area .intro-video-content .title {
  font-size: 55px;
  color: #fff;
  padding-right: 22px;
  padding-top: 20px;
  padding-bottom: 16px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .intro-video-area .intro-video-content .title {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .intro-video-area .intro-video-content .title {
    font-size: 46px;
  }
}

@media (max-width: 767px) {
  .intro-video-area .intro-video-content .title {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .intro-video-area .intro-video-content .title {
    font-size: 26px;
  }
}

.intro-video-area .intro-video-content .text-1 {
  font-size: 24px;
  color: #a1bbd7;
  padding-bottom: 25px;
}

@media (max-width: 767px) {
  .intro-video-area .intro-video-content .text-1 {
    font-size: 24px;
  }
}

@media (max-width: 575px) {
  .intro-video-area .intro-video-content .text-1 {
    font-size: 18px;
  }
}

.intro-video-area .intro-video-content .text-2 {
  color: #c5d5e7;
  font-size: 16px;
}

.intro-video-area .intro-video-content .main-btn {
  margin-top: 45px;
}

.intro-video-area .intro-thumb {
  position: relative;
  z-index: 10;
}

.intro-video-area .intro-thumb::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-bottom: 400px solid #0c59db;
  border-left: 400px solid transparent;
  right: 0;
  bottom: -42px;
  z-index: -1;
}

@media (max-width: 575px) {
  .intro-video-area .intro-thumb::before {
    border-width: 200px;
    right: -15px;
    bottom: -20px;
  }
}

.intro-video-area .intro-thumb .video-popup {
  height: 80px;
  width: 80px;
  color: #fff;
  background: #0c59db;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.intro-video-area .intro-thumb .video-popup::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #0c59db;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnBefore 1.8s linear infinite;
  animation: pulseBtnBefore 1.8s linear infinite;
}

.intro-video-area .intro-thumb .video-popup::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #0c59db;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnAfter 1.5s linear infinite;
  animation: pulseBtnAfter 1.5s linear infinite;
}

.intro-video-area-two {
  padding-top: 120px;
  padding-bottom: 620px;
  position: relative;
}

.intro-video-area-two .video-popup {
  height: 100px;
  width: 100px;
  color: #fff;
  background: #0c59db;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.intro-video-area-two .video-popup::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnBefore 1.8s linear infinite;
  animation: pulseBtnBefore 1.8s linear infinite;
}

.intro-video-area-two .video-popup::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnAfter 1.5s linear infinite;
  animation: pulseBtnAfter 1.5s linear infinite;
}

.intro-video-area-two .video-thumb-1 {
  position: absolute;
  top: -40px;
  left: 30px;
  text-align: left;
}

@media (max-width: 991px) {
  .intro-video-area-two .video-thumb-1 img {
    max-width: 65%;
  }
}

@media (max-width: 767px) {
  .intro-video-area-two .video-thumb-1 img {
    max-width: 45%;
  }
}

.intro-video-area-two .video-thumb-2 {
  position: absolute;
  top: -70px;
  right: 30px;
  text-align: right;
}

@media (max-width: 991px) {
  .intro-video-area-two .video-thumb-2 img {
    max-width: 65%;
  }
}

@media (max-width: 767px) {
  .intro-video-area-two .video-thumb-2 img {
    max-width: 50%;
  }
}

.intro-video-with-shape {
  position: relative;
  z-index: 1;
}

.intro-video-with-shape::before {
  position: absolute;
  content: "";
  bottom: -20px;
  left: -20px;
  width: 0;
  height: 0;
  border-bottom: 400px solid #0c59db;
  border-right: 400px solid transparent;
  z-index: -1;
}

@media (max-width: 575px) {
  .intro-video-with-shape::before {
    border-width: 200px;
  }
}

.intro-video-with-shape .video-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-75%, -50%);
  -ms-transform: translate(-75%, -50%);
  transform: translate(-75%, -50%);
  height: 120px;
  width: 120px;
  text-align: center;
  border-radius: 50%;
  line-height: 120px;
  background: #0c59db;
  color: #fff;
  font-size: 20px;
  z-index: 2;
}

@media (max-width: 575px) {
  .intro-video-with-shape .video-popup {
    height: 80px;
    width: 80px;
    line-height: 80px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.intro-video-with-shape .video-popup::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px solid #0c59db;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnBefore 1.8s linear infinite;
  animation: pulseBtnBefore 1.8s linear infinite;
}

.intro-video-with-shape .video-popup::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px solid #0c59db;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: pulseBtnAfter 1.5s linear infinite;
  animation: pulseBtnAfter 1.5s linear infinite;
}

/*===== Accordion Css =====*/
.accordion-one {
  border: none;
}

.accordion-one .card {
  border-radius: 0;
  border: 0;
}

.accordion-one .card:first-child {
  border-top: 0;
}

.accordion-one .card:not(:last-child) {
  margin-bottom: 10px;
}

.accordion-one .card .card-header {
  border-bottom: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.accordion-one .card .card-header .btn-link {
  line-height: 50px;
  padding: 0 0 0 0px;
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  font-family: "Alegreya Sans";
  width: 100%;
  color: #0c59db;
  position: relative;
}

@media (max-width: 575px) {
  .accordion-one .card .card-header .btn-link {
    font-size: 16px;
  }
}

.accordion-one .card .card-header .btn-link i {
  padding-right: 5px;
}

.accordion-one .card .card-header .btn-link.collapsed {
  color: #616161;
}

.accordion-one .card .card-body {
  padding: 0 10px 10px 25px;
}

.accordion-one .card .card-body p {
  font-size: 15px;
  color: #616161;
}

.accordion-one.white-version .card {
  background-color: transparent;
}

.accordion-one.white-version .card .card-header .btn-link {
  color: #fff;
  font-family: "Alegreya Sans", sans-serif;
}

@media (max-width: 575px) {
  .accordion-one.white-version .card {
    margin: 0;
  }
}

.accordion-one.white-version .card .card-body p {
  color: #fff;
}

.accordion-two {
  border: none;
}

.accordion-two .card {
  border-radius: 0;
  border: 0;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
}

.accordion-two .card:first-child {
  border-top: 0;
}

.accordion-two .card:not(:last-child) {
  margin-bottom: 20px;
}

.accordion-two .card .card-header {
  border-bottom: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.accordion-two .card .card-header .btn-link {
  padding: 20px 55px 20px 40px;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  color: #002249;
  position: relative;
}

@media (max-width: 767px) {
  .accordion-two .card .card-header .btn-link {
    padding: 0 0 0 40px;
    line-height: 60px;
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .accordion-two .card .card-header .btn-link {
    padding: 10px 30px 10px 20px;
    line-height: 20px;
    font-size: 15px;
  }
}

.accordion-two .card .card-header .btn-link.collapsed {
  color: #7a95b4;
}

.accordion-two .card .card-header .btn-link.collapsed::before {
  content: "";
  color: #cddae8;
}

.accordion-two .card .card-header .btn-link::before {
  position: absolute;
  content: "";
  right: 36px;
  font-family: "Font Awesome 5 Pro";
}

@media (max-width: 575px) {
  .accordion-two .card .card-header .btn-link::before {
    right: 18px;
  }
}

.accordion-two .card .card-body {
  padding: 0 40px 20px;
}

.accordion-two .card .card-body p {
  font-size: 14px;
  color: #81a3bb;
}

.accordion-three {
  border: 2px solid #e3eeff;
}

.accordion-three .card {
  border-radius: 0;
  border: 0;
  border-top: 2px solid #e3eeff;
}

.accordion-three .card:first-child {
  border-top: 0;
}

.accordion-three .card .card-header {
  border-bottom: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.accordion-three .card .card-header .btn-link {
  padding: 16px 55px 16px 40px;
  text-decoration: none;
  font-weight: 700;
  color: #002249;
  font-size: 16px;
  width: 100%;
  color: #0c59db;
  position: relative;
}

@media (max-width: 575px) {
  .accordion-three .card .card-header .btn-link {
    padding: 16px 40px 16px 20px;
  }
}

.accordion-three .card .card-header .btn-link.collapsed {
  color: #002249;
}

.accordion-three .card .card-header .btn-link.collapsed::before {
  content: "";
  color: #cddae8;
}

.accordion-three .card .card-header .btn-link::before {
  position: absolute;
  content: "";
  right: 36px;
  font-family: "Font Awesome 5 Pro";
}

@media (max-width: 575px) {
  .accordion-three .card .card-header .btn-link::before {
    right: 15px;
  }
}

.accordion-three .card .card-body {
  padding: 0 40px 20px;
}

@media (max-width: 575px) {
  .accordion-three .card .card-body {
    padding: 0 20px 20px;
  }
}

.accordion-three.accordion-three-two {
  border: none;
}

.accordion-three.accordion-three-two .card {
  border: 2px solid #e3eeff;
}

.accordion-three.accordion-three-two .card:not(:last-child) {
  margin-bottom: 20px;
}

.accordion-three.accordion-three-two .card .card-header .btn-link.collapsed {
  color: #002249;
}

.accordion-three.accordion-three-two .card .card-header .btn-link.collapsed::before {
  content: "";
}

.accordion-three.accordion-three-two .card .card-header .btn-link::before {
  position: absolute;
  content: "";
  font-weight: 400;
}

.accordion-four {
  border: none;
}

.accordion-four .card {
  border-radius: 40px !important;
  border: 0;
  border-top: 2px solid transparent;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
}

.accordion-four .card:first-child {
  border-top: 0;
}

.accordion-four .card .card-header {
  border-bottom: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.accordion-four .card .card-header .btn-link {
  padding: 22px 60px 22px 40px;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  color: #7a95b4;
  position: relative;
}

@media (max-width: 767px) {
  .accordion-four .card .card-header .btn-link {
    padding: 22px 50px 22px 20px;
  }
}

.accordion-four .card .card-header .btn-link i {
  color: #0c59db;
  padding-right: 20px;
}

.accordion-four .card .card-header .btn-link.collapsed {
  font-size: 16px;
  color: #436584;
}

.accordion-four .card .card-header .btn-link.collapsed::before {
  content: "";
  color: #99b1c8;
}

.accordion-four .card .card-header .btn-link::before {
  position: absolute;
  content: "";
  right: 36px;
  font-size: 16px;
  font-family: "Font Awesome 5 Pro";
  color: #99b1c8;
}

@media (max-width: 767px) {
  .accordion-four .card .card-header .btn-link::before {
    right: 20px;
  }
}

.accordion-four .card .card-body {
  padding: 0 40px 25px;
}

.accordion-four .card .card-body p {
  font-size: 14px;
  color: #81a3bb;
}

/*======= History timeline =======*/
.history-timeline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-left: -60px;
  margin-right: -60px;
  padding: 30px 0;
}

@media (max-width: 767px) {
  .history-timeline {
    padding: 30px 0px 30px 100px;
    margin: 0;
  }
}

@media (max-width: 575px) {
  .history-timeline {
    padding: 0;
  }
}

.history-timeline::before {
  position: absolute;
  content: "";
  top: 0;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 2px;
  background: #e3eeff;
}

@media (max-width: 767px) {
  .history-timeline::before {
    left: 30px;
  }
}

@media (max-width: 575px) {
  .history-timeline::before {
    display: none;
  }
}

.history-timeline::after {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 60px;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #0c59db;
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "";
  font-size: 20px;
}

@media (max-width: 767px) {
  .history-timeline::after {
    left: 0;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (max-width: 575px) {
  .history-timeline::after {
    display: none;
  }
}

.history-timeline .history-item {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  margin-bottom: 80px;
  padding-left: 60px;
  padding-right: 60px;
}

@media (max-width: 767px) {
  .history-timeline .history-item {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-bottom: 60px;
    padding: 0;
  }
}

.history-timeline .history-item .number-box {
  height: 60px;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 4px solid #9edbff;
  background-color: #fff;
  color: #616161;
  z-index: 5;
  position: absolute;
  top: -30px;
  right: -30px;
}

@media (max-width: 767px) {
  .history-timeline .history-item .number-box {
    left: -100px;
    right: auto;
  }
}

.history-timeline .history-item:nth-child(2) {
  margin-top: 280px;
}

@media (max-width: 767px) {
  .history-timeline .history-item:nth-child(2) {
    margin-top: 0;
  }
}

.history-timeline .history-item:nth-child(even) .number-box {
  right: auto;
  left: -30px;
}

@media (max-width: 767px) {
  .history-timeline .history-item:nth-child(even) .number-box {
    left: -100px;
    right: auto;
  }
}

.history-timeline .history-item:nth-last-child(1), .history-timeline .history-item:nth-last-child(2) {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .history-timeline .history-item:nth-last-child(1), .history-timeline .history-item:nth-last-child(2) {
    margin-bottom: 60px;
  }
}

.history-timeline .history-item .history-thumb {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}

.history-timeline .history-item .history-content {
  padding: 35px 40px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
}

@media (max-width: 991px) {
  .history-timeline .history-item .history-content {
    padding: 35px 30px;
  }
}

.history-timeline .history-item .history-content .history-year {
  color: #0c59db;
  font-weight: 700;
}

.history-timeline .history-item .history-content .title {
  font-size: 26px;
  padding-top: 9px;
}

@media (max-width: 1199px) {
  .history-timeline .history-item .history-content .title {
    font-size: 22px;
  }
}

/*=======  Brand Slider  =======*/
.brand-slider .brand-item {
  text-align: center;
}

.brand-slider .brand-item a {
  opacity: 0.2;
}

.brand-slider .brand-item a:hover {
  opacity: 1;
}

.brand-slider .brand-item.full-opacity a {
  opacity: 1;
}

.brand-slider .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*=======  Footer  =======*/
.footer-area {
  background-color: #00152e;
}

.footer-area .footer-widgets {
  padding-top: 100px;
  padding-bottom: 50px;
}

.footer-area .footer-widgets .widget {
  margin-bottom: 50px;
}

.footer-area .footer-widgets .widget,
.footer-area .footer-widgets .widget p,
.footer-area .footer-widgets .widget a {
  color: #c2d1e2;
}

.footer-area .footer-widgets .widget .widget-title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 20px;
  color: #fff;
}

@media (max-width: 991px) {
  .footer-area .footer-widgets .widget .widget-title {
    font-size: 28px;
  }
}

.footer-area .footer-widgets .widget.about-widget {
  font-size: 16px;
}

.footer-area .footer-widgets .widget.about-widget .logo {
  margin-bottom: 30px;
}

.footer-area .footer-widgets .widget.about-widget p:not(:last-child) {
  margin-bottom: 30px;
}

.footer-area .footer-widgets .widget.nav-widget .footer-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-area .footer-widgets .widget.nav-widget .footer-list ul {
  display: inline-block;
}

.footer-area .footer-widgets .widget.nav-widget .footer-list ul li {
  line-height: 40px;
  margin-right: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .footer-area .footer-widgets .widget.nav-widget .footer-list ul li {
    margin-right: 20px;
  }
}

.footer-area .footer-widgets .widget.nav-widget .footer-list ul li i {
  padding-right: 6px;
}

.footer-area .footer-widgets .widget.contact-widget ul li {
  line-height: 36px;
  font-size: 20px;
  font-weight: 400;
}

.footer-area .footer-widgets .widget.contact-widget p {
  margin-top: 25px;
}

.footer-area .footer-widgets .widget.contact-widget p span {
  font-weight: 700;
  color: #fff;
}

.footer-area .footer-widgets .widget.contact-widget a {
  margin-top: 8px;
  color: #0c59db;
  font-weight: 700;
}

.footer-area .footer-widgets .widget.contact-widget a i {
  padding-right: 6px;
}

.footer-area .footer-copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  padding: 25px 0;
  color: #c2d1e2;
}

.footer-area .footer-copyright a {
  color: #fff;
  font-weight: 700;
}

.footer-area.footer-area-two {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.footer-area.footer-area-two::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #002249;
  opacity: 0.9;
}

.footer-area.footer-area-two::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: -260px;
  bottom: -400px;
  height: 700px;
  width: 700px;
  border-radius: 50%;
  background: #0c59db;
  opacity: 0.4;
}

@media (max-width: 991px) {
  .footer-area.footer-area-two::after {
    width: 500px;
    height: 500px;
    left: -200px;
    bottom: -200px;
  }
}

@media (max-width: 767px) {
  .footer-area.footer-area-two::after {
    display: none;
  }
}

.footer-area.footer-area-two .container {
  position: relative;
  z-index: 1;
}

.footer-area.footer-area-two .container::before {
  content: "";
  position: absolute;
  right: -100px;
  bottom: 15%;
  height: 105px;
  width: 105px;
  border-radius: 50%;
  background: #0c59db;
}

@media (max-width: 1199px) {
  .footer-area.footer-area-two .container::before {
    right: 0;
  }
}

.footer-area.footer-area-two .container::after {
  content: "";
  position: absolute;
  right: -30px;
  bottom: calc(15% + 105px + 10px);
  height: 33px;
  width: 33px;
  border-radius: 50%;
  background: #00e8cf;
}

@media (max-width: 1199px) {
  .footer-area.footer-area-two .container::after {
    right: 20px;
  }
}

.footer-area.footer-area-three {
  background-color: #f5faff;
}

.footer-area.footer-area-three .footer-widgets .widget,
.footer-area.footer-area-three .footer-widgets .widget p,
.footer-area.footer-area-three .footer-widgets .widget a {
  color: #81a3bb;
}

.footer-area.footer-area-three .footer-widgets .widget a:hover {
  color: #0c59db;
}

.footer-area.footer-area-three .footer-widgets .widget .widget-title {
  color: #002249;
}

.footer-area.footer-area-three .footer-widgets .widget.contact-widget p span {
  font-weight: 700;
  color: #002249;
}

.footer-area.footer-area-three .footer-copyright {
  border-color: #e2edf3;
  color: #81a3bb;
}

.footer-area.footer-area-three .footer-copyright a {
  color: #002249;
}

/*=== Back to top css =======*/
.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 50px;
  display: none;
  z-index: 998;
}

.back-to-top a {
  color: #fff;
  background: #0c59db;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
}

/*=======  Page Title  =======*/
.page-title-area {
  background: #a9c7ec;
  position: relative;
  z-index: 1;
  text-align: center;
  padding-top: 280px;
  padding-bottom: 120px;
}

@media (max-width: 575px) {
  .page-title-area {
    padding-top: 180px;
  }
}

.page-title-area::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/banner/banner-gradient-bg-3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.page-title-area .title {
  font-size: 80px;
  margin-bottom: 10px;
  text-align: center !important;
}

@media (max-width: 991px) {
  .page-title-area .title {
    font-size: 62px;
  }
}

@media (max-width: 575px) {
  .page-title-area .title {
    font-size: 38px;
  }
}

.page-title-area .breadcrumb-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page-title-area .breadcrumb-nav li {
  font-size: 24px;
  font-weight: 700;
  position: relative;
  line-height: 1;
}

@media (max-width: 575px) {
  .page-title-area .breadcrumb-nav li {
    font-size: 18px;
  }
}

.page-title-area .breadcrumb-nav li:not(:last-child)::after {
  content: "|";
  margin: 0 8px;
}

.page-title-area .breadcrumb-nav li,
.page-title-area .breadcrumb-nav li a {
  color: #69a1bb;
}

/*=======  Products  =======*/
.product-item {
  overflow: hidden;
}

.product-item .product-thumb {
  position: relative;
}

.product-item .product-thumb img {
  width: 100%;
}

.product-item .product-thumb .product-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 34, 73, 0.6);
  opacity: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.product-item .product-thumb .product-overlay ul {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  white-space: nowrap;
  -webkit-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}

.product-item .product-thumb .product-overlay ul li {
  display: inline-block;
}

.product-item .product-thumb .product-overlay ul li a {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  background: #fff;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.product-item .product-thumb .product-overlay ul li a:hover {
  color: #fff;
  background: #0c59db;
}

.product-item:hover .product-thumb .product-overlay {
  opacity: 1;
}

.product-item:hover .product-thumb .product-overlay ul {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.product-item .product-content {
  padding: 25px 0;
  text-align: center;
  background-color: #fff;
}

.product-item .product-content .title {
  font-size: 18px;
}

.product-item .product-content .title a {
  color: #232323;
}

.product-item .product-content .price {
  padding-top: 5px;
  color: #0c59db;
  font-size: 14px;
  font-weight: 700;
}

.product-item .product-content .price del {
  color: #b2c0d3;
}

.shop-top-bar {
  margin-bottom: 20px;
}

.shop-top-bar .shop-notice {
  color: #002249;
  font-weight: 700;
}

.shop-top-bar .shop-search-form {
  position: relative;
}

@media (max-width: 767px) {
  .shop-top-bar .shop-search-form {
    margin-bottom: 20px;
  }
}

.shop-top-bar .shop-search-form input {
  height: 60px;
  border-radius: 30px;
  width: 300px;
}

@media (max-width: 767px) {
  .shop-top-bar .shop-search-form input {
    width: 100%;
    border-radius: 0;
  }
}

.shop-top-bar .shop-search-form button {
  border: none;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 22;
  color: #0c59db;
}

/*======= Shop Sidebar =======*/
@media (max-width: 1199px) {
  .shop-sidebar {
    margin-top: 50px;
  }
}

.shop-sidebar .shop-widget {
  padding: 30px 40px;
  background-color: #fff;
}

.shop-sidebar .shop-widget:not(:last-child) {
  margin-bottom: 30px;
}

.shop-sidebar .shop-widget .widget-title {
  font-size: 20px;
  position: relative;
  margin-bottom: 20px;
  padding-left: 45px;
}

.shop-sidebar .shop-widget .widget-title::before, .shop-sidebar .shop-widget .widget-title::after {
  height: 4px;
  width: 18px;
  position: absolute;
  content: "";
  background: #0c59db;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.shop-sidebar .shop-widget .widget-title::after {
  width: 4px;
  left: 25px;
}

.shop-sidebar .shop-widget.nav-widget li a {
  color: #75a1af;
  font-size: 14px;
  line-height: 35px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.shop-sidebar .shop-widget.nav-widget li a i {
  padding-right: 15px;
}

.shop-sidebar .shop-widget.nav-widget li a:hover {
  color: #002249;
}

.shop-sidebar .shop-widget.tag-widget li {
  display: inline-block;
}

.shop-sidebar .shop-widget.tag-widget li a {
  color: #0c59db;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 700;
  margin-right: 8px;
  margin-bottom: 8px;
}

.shop-sidebar .shop-widget.tag-widget li a:hover {
  color: #232323;
}

.shop-sidebar .shop-widget.subscribe-widget p {
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 15px;
}

.shop-sidebar .shop-widget.subscribe-widget input {
  width: 100%;
  border: 2px solid #edf1ff;
  color: #7585af;
  height: 50px;
}

.shop-sidebar .shop-widget.subscribe-widget button {
  width: 100%;
  height: 50px;
  border: none;
  background-color: #0c59db;
  color: #fff;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.shop-sidebar .shop-widget.subscribe-widget button:hover {
  background-color: #002249;
}

.shop-sidebar .shop-widget.ad-widget {
  padding: 0;
  position: relative;
  z-index: 2;
}

.shop-sidebar .shop-widget.ad-widget img {
  width: 100%;
}

.shop-sidebar .shop-widget.ad-widget a {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  color: #0c59db;
  padding: 10px 30px;
}

.shop-sidebar .shop-widget.ad-widget a:hover {
  background-color: #0c59db;
  color: #fff;
}

/*======= Shop Details =======*/
.product-gallery-wrap {
  padding-right: 45px;
}

@media (max-width: 991px) {
  .product-gallery-wrap {
    padding-right: 0;
  }
}

.product-gallery-wrap .product-thumb-slider img {
  cursor: pointer;
}

.product-summery .product-rating {
  margin-top: 12px;
}

.product-summery .product-rating ul {
  padding-bottom: 25px;
}

.product-summery .product-rating ul li {
  display: inline-block;
  margin-right: 2px;
}

.product-summery .product-rating ul li i {
  color: #ffb400;
}

.product-summery .product-rating ul li span {
  font-size: 14px;
  color: #63688e;
}

.product-summery .cat {
  color: #0c59db;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 5px;
}

.product-summery .title {
  font-size: 30px;
  margin-bottom: 25px;
}

.product-summery .price {
  margin-bottom: 20px;
  color: #0c59db;
  font-size: 50px;
  line-height: 1;
}

.product-summery .price del {
  color: #bfc7d3;
  margin-left: 15px;
}

@media (max-width: 575px) {
  .product-summery .price {
    font-size: 30px;
  }
}

.product-summery .product-meta {
  margin-bottom: 30px;
}

.product-summery .product-meta li,
.product-summery .product-meta li a {
  color: #63688e;
}

.product-summery .product-meta li span {
  color: #63688e;
  font-weight: 700;
  width: 140px;
}

.product-summery .product-meta li.stock {
  color: #0c59db;
  font-weight: 700;
}

.product-summery .product-meta li:not(:last-child) {
  margin-bottom: 5px;
}

.product-summery .short-desc {
  color: #81a3bb;
  padding-right: 30px;
  font-size: 16px;
  margin-bottom: 20px;
}

.product-summery .product-quantity button {
  width: 50px;
  height: 50px;
  padding: 0;
  background-color: #fff;
  font-size: 18px;
  color: #838b97;
  border: 1px solid #dce1e5;
}

.product-summery .product-quantity input {
  width: 60px;
  height: 50px;
  border: 1px solid #dce1e5;
  padding: 0 10px;
  text-align: center;
  margin: 0 0;
  color: #838b97;
}

.product-summery .product-quantity input::-webkit-outer-spin-button,
.product-summery .product-quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.product-summery .product-quantity input[type=number] {
  -moz-appearance: textfield;
}

.product-summery .social-share li {
  display: inline-block;
}

.product-summery .social-share a {
  height: 50px;
  width: 50px;
  background: #d1edff;
  text-align: center;
  line-height: 50px;
  color: #7fb8dc;
  margin-left: 20px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 575px) {
  .product-summery .social-share a {
    margin-right: 10px;
    margin-left: 0px;
    margin-top: 20px;
  }
}

.product-summery .social-share a:hover {
  color: #fff;
  background: #0c59db;
}

.product-summery .main-btn {
  margin-top: 30px;
}

.product-details-tab {
  padding-top: 80px;
}

.product-details-tab .nav {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.product-details-tab .nav::before {
  content: "";
  position: absolute;
  left: 45px;
  right: 45px;
  background-color: #e7ecf0;
  height: 2px;
  top: 50%;
  z-index: -1;
  border-radius: 30px;
}

@media (max-width: 575px) {
  .product-details-tab .nav::before {
    display: none;
  }
}

.product-details-tab .nav .nav-item a {
  background: #fff;
  line-height: 50px;
  padding: 0 45px;
  border-radius: 0;
  margin: 0 10px;
  border: 2px solid #e7ecf0;
  color: #63688e;
  font-weight: 700;
}

@media (max-width: 767px) {
  .product-details-tab .nav .nav-item a {
    padding: 0 25px;
  }
}

@media (max-width: 575px) {
  .product-details-tab .nav .nav-item a {
    margin: 5px;
  }
}

.product-details-tab .nav .nav-item a.active, .product-details-tab .nav .nav-item a:hover {
  background-color: #0c59db;
  color: #fff;
  border-color: #0c59db;
}

.product-details-tab .tab-content {
  padding-top: 50px;
}

.product-details-tab .tab-content .title {
  font-size: 30px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .product-details-tab .tab-content .title {
    font-size: 24px;
  }
}

.product-details-tab .tab-content p {
  color: #81a3bb;
  font-size: 16px;
}

.product-details-tab .tab-content .specifications-list li {
  padding: 15px 0;
  color: #63688e;
  font-size: 16px;
}

.product-details-tab .tab-content .specifications-list li:not(:last-child) {
  border-bottom: 1px solid #cbeaff;
}

.product-details-tab .tab-content .specifications-list li span {
  width: 22%;
  color: #002249;
  font-weight: 700;
}

@media (max-width: 767px) {
  .product-details-tab .tab-content .specifications-list li span {
    width: 40%;
  }
}

/*======= Shoping Cart =======*/
@media (max-width: 991px) {
  .cart-table {
    margin-bottom: 40px;
  }
}

.cart-table table {
  border-bottom: 1px solid #dee2e6;
  width: 720px;
}

.cart-table table thead th {
  border: none;
}

.cart-table table tbody tr td {
  color: #002249;
  font-weight: 600;
  vertical-align: middle;
}

.cart-table table tbody tr td.product-thumbnail a img {
  max-width: 80px;
}

.cart-table table tbody tr td.product-name a {
  color: #002249;
  font-size: 18px;
}

.cart-table table tbody tr td.product-name a:hover {
  color: #0c59db;
}

.cart-table table tbody tr td.product-quantity input {
  width: 60px;
  height: 50px;
  border: 1px solid #dce1e5;
  padding: 0 10px;
  text-align: center;
  color: #838b97;
}

.shopping-coupon-code {
  position: relative;
}

@media (max-width: 767px) {
  .shopping-coupon-code {
    margin-bottom: 30px;
  }
}

.shopping-coupon-code input[type=text] {
  width: 100%;
  height: 60px;
  background: #fff;
  padding: 0 20px;
  border: none;
}

.shopping-coupon-code .main-btn {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px;
}

@media (max-width: 575px) {
  .shopping-coupon-code .main-btn {
    position: unset;
    margin-top: 10px;
  }
}

.shopping-coupon-code .main-btn:hover {
  background: #0c59db;
}

.cart-total-box {
  padding: 40px;
  background: #fff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  margin-top: 60px;
}

@media (max-width: 575px) {
  .cart-total-box {
    padding: 30px;
  }
}

.cart-total-box .title {
  margin-bottom: 20px;
  font-size: 26px;
}

.cart-total-box .list {
  margin: 0 0 25px;
}

.cart-total-box .list li {
  padding: 10px 15px;
  border: 1px solid #eaedff;
  font-weight: 600;
  overflow: hidden;
}

.cart-total-box .list li:first-child {
  border-bottom: none;
}

.cart-total-box .list li:last-child {
  border-top: none;
}

.cart-total-box .list li span {
  float: right;
}

@media (max-width: 575px) {
  .cart-total-box .main-btn {
    padding: 20px 30px;
  }
}

/*======= Checkout =======*/
.user-actions {
  background: #fff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 15px 20px;
  border-top: 2px solid #0c59db;
}

.billing-details .title,
.order-details .title {
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .billing-details .title,
  .order-details .title {
    font-size: 22px;
  }
}

.billing-details .form-group label {
  display: block;
  color: #002249;
  font-weight: 600;
}

.billing-details .form-group label span {
  color: red;
}

.billing-details .form-group select {
  height: 50px;
  width: 100%;
  border: solid 1px #e3eeff;
}

.billing-details .form-group input[type=text],
.billing-details .form-group textarea {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border: solid 1px #e3eeff;
}

.billing-details .form-group textarea {
  padding-top: 20px;
  height: 130px;
}

.billing-details .form-check {
  margin-bottom: 20px;
}

.billing-details .form-check label {
  font-weight: 600;
  color: #002249;
}

.order-details .order-table .table thead tr th {
  border: none;
}

.order-details .order-table tbody tr td {
  color: #002249;
  font-weight: 600;
  vertical-align: middle;
  text-transform: capitalize;
}

.order-details .order-table tbody tr td.product-name a {
  color: #002249;
  font-size: 18px;
}

.order-details .order-table tbody tr td.product-name a:hover {
  color: #0c59db;
}

.order-details .payment-box {
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background: #fff;
  padding: 30px;
  color: #81a3bb;
}

.order-details .payment-box .main-btn {
  margin-top: 20px;
}

/*=======  Blog Standard  =======*/
.single-blog-standard .blog-standard-thumb {
  position: relative;
  margin-bottom: -2px;
}

.single-blog-standard .blog-standard-thumb img {
  width: 100%;
}

.single-blog-standard .blog-standard-thumb .video-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  background: #0c59db;
  color: #fff;
  border-radius: 50%;
  font-size: 20px;
}

.single-blog-standard .blog-standard-content {
  border: 2px solid #ededed;
  padding: 40px;
}

@media (max-width: 767px) {
  .single-blog-standard .blog-standard-content {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .single-blog-standard .blog-standard-content {
    padding: 30px 25px;
  }
}

.single-blog-standard .blog-standard-content .cat {
  background: #0c59db;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 3px;
  padding: 0 15px;
  line-height: 30px;
  text-transform: uppercase;
}

@media (max-width: 575px) {
  .single-blog-standard .blog-standard-content .cat {
    letter-spacing: 1px;
    font-size: 12px;
  }
}

.single-blog-standard .blog-standard-content .title {
  font-size: 32px;
  padding-top: 25px;
  line-height: 1.3;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .single-blog-standard .blog-standard-content .title {
    padding-right: 0;
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .single-blog-standard .blog-standard-content .title {
    font-size: 24px;
  }
}

@media (max-width: 575px) {
  .single-blog-standard .blog-standard-content .title {
    font-size: 22px;
  }
}

.single-blog-standard .blog-standard-content .post-meta {
  padding: 20px 0;
}

.single-blog-standard .blog-standard-content .post-meta li {
  display: inline-block;
  color: #69a1bb;
  font-size: 14px;
  margin-right: 28px;
}

@media (max-width: 575px) {
  .single-blog-standard .blog-standard-content .post-meta li {
    margin-right: 10px;
  }
}

.single-blog-standard .blog-standard-content .post-meta li i {
  padding-right: 6px;
}

.single-blog-standard .blog-standard-content p {
  color: #69a1bb;
  font-size: 15px;
  padding-bottom: 35px;
}

.single-blog-standard .blog-standard-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 7px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 575px) {
  .single-blog-standard .blog-standard-footer {
    display: block;
  }
}

@media (max-width: 575px) {
  .single-blog-standard .blog-standard-footer .footer-left {
    margin-bottom: 20px;
  }
}

.single-blog-standard .blog-standard-footer .footer-left a {
  font-weight: 700;
  color: #002249;
}

.single-blog-standard .blog-standard-footer .footer-left a span {
  color: #616161;
}

.single-blog-standard .blog-standard-footer .footer-left img {
  margin-right: 10px;
}

.single-blog-standard .blog-standard-footer .footer-right a {
  font-weight: 700;
  letter-spacing: 2px;
  color: #0c59db;
  text-transform: uppercase;
  font-size: 14px;
}

.single-blog-standard .blog-standard-footer .footer-right a i {
  padding-right: 15px;
}

/*=======  Blog Grid  =======*/
.single-blog-grid {
  position: relative;
}

.single-blog-grid img {
  width: 100%;
}

.single-blog-grid::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 34, 73, 0.1)), color-stop(1%, rgba(0, 34, 73, 0.01)), to(rgba(0, 34, 73, 0.6)));
  background-image: -o-linear-gradient(top, rgba(0, 34, 73, 0.1) 0%, rgba(0, 34, 73, 0.01) 1%, rgba(0, 34, 73, 0.6) 100%);
  background-image: linear-gradient(180deg, rgba(0, 34, 73, 0.1) 0%, rgba(0, 34, 73, 0.01) 1%, rgba(0, 34, 73, 0.6) 100%);
}

.single-blog-grid .cat {
  background: #0c59db;
  color: #fff;
  line-height: 26px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  left: 30px;
  top: 40px;
  z-index: 2;
}

.single-blog-grid .blog-grid-overlay {
  position: absolute;
  left: 30px;
  bottom: 40px;
  right: 30px;
  z-index: 2;
}

.single-blog-grid .blog-grid-overlay .title {
  font-size: 24px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .single-blog-grid .blog-grid-overlay .title {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .single-blog-grid .blog-grid-overlay .title {
    font-size: 20px;
  }
}

.single-blog-grid .blog-grid-overlay .title a {
  color: #fff;
}

.single-blog-grid .blog-grid-overlay .post-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 10px;
}

.single-blog-grid .blog-grid-overlay .post-meta li {
  display: inline-block;
}

.single-blog-grid .blog-grid-overlay .post-meta li a {
  color: #fff;
  opacity: 0.8;
}

.single-blog-grid .blog-grid-overlay .post-meta li a:hover {
  opacity: 1;
}

/*=======  Blog Sidebar  =======*/
.blog-sidebar {
  padding-left: 20px;
}

@media (max-width: 1199px) {
  .blog-sidebar {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .blog-sidebar {
    margin-top: 50px;
  }
}

.blog-sidebar .widget {
  border: 2px solid #ededed;
  padding: 35px 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-sidebar .widget {
    padding: 30px;
  }
}

.blog-sidebar .widget:not(:last-child) {
  margin-bottom: 30px;
}

.blog-sidebar .widget .widget-title {
  font-size: 20px;
  padding-left: 40px;
  position: relative;
  margin-bottom: 30px;
}

.blog-sidebar .widget .widget-title::before {
  position: absolute;
  content: "";
  height: 4px;
  width: 4px;
  left: 21px;
  top: 12px;
  background: #0c59db;
}

.blog-sidebar .widget .widget-title::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 18px;
  left: 0;
  top: 12px;
  background: #0c59db;
}

.blog-sidebar .widget .about-contnent {
  text-align: center;
}

.blog-sidebar .widget .about-contnent .title {
  font-size: 18px;
  padding-top: 25px;
}

.blog-sidebar .widget .about-contnent p {
  font-size: 14px;
  padding: 10px 5px 15px;
}

.blog-sidebar .widget .about-contnent ul li {
  display: inline-block;
}

.blog-sidebar .widget .about-contnent ul li a {
  color: #b3bed3;
  font-size: 14px;
  margin: 0 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.blog-sidebar .widget .about-contnent ul li a:hover {
  color: #0c59db;
}

.blog-sidebar .widget.search-widget form {
  position: relative;
}

.blog-sidebar .widget.search-widget form input {
  width: 100%;
  background: #f5faff;
  border: 0;
  font-size: 12px;
  color: #69a1bb;
  height: 60px;
}

.blog-sidebar .widget.search-widget form button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  text-align: center;
  border: 0;
  background: #0c59db;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.blog-sidebar .widget.search-widget form button:hover {
  background-color: #002249;
}

.blog-sidebar .widget .news-feed-items .news-feed-item {
  position: relative;
  padding-left: 100px;
}

.blog-sidebar .widget .news-feed-items .news-feed-item:not(:last-child) {
  margin-bottom: 30px;
  border-bottom: 1px solid #e3eeff;
  padding-bottom: 30px;
}

.blog-sidebar .widget .news-feed-items .news-feed-item .title {
  font-size: 16px;
}

.blog-sidebar .widget .news-feed-items .news-feed-item span {
  color: #69a1bb;
  font-size: 12px;
  padding-top: 5px;
}

.blog-sidebar .widget .news-feed-items .news-feed-item span i {
  padding-right: 6px;
}

.blog-sidebar .widget .news-feed-items .news-feed-item img {
  position: absolute;
  left: 0;
  top: -5px;
}

.blog-sidebar .widget.categories-widget ul li:not(:last-child) {
  margin-bottom: 10px;
}

.blog-sidebar .widget.categories-widget ul li a {
  width: 100%;
  background: #152136;
  line-height: 50px;
  padding-left: 30px;
  color: #fff;
  font-size: 14px;
  position: relative;
}

.blog-sidebar .widget.categories-widget ul li a span {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0;
  color: #fff;
  background: #091222;
  font-weight: 700;
}

.blog-sidebar .widget.social-links li {
  display: inline-block;
}

.blog-sidebar .widget.social-links li a {
  height: 40px;
  width: 40px;
  background: #002249;
  text-align: center;
  line-height: 40px;
  color: #fff;
  margin: 0 4px;
}

.blog-sidebar .widget .twitter-feeds .twitter-feed-item {
  position: relative;
  padding-left: 35px;
}

.blog-sidebar .widget .twitter-feeds .twitter-feed-item:not(:last-child) {
  border-bottom: 1px solid #e3eeff;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.blog-sidebar .widget .twitter-feeds .twitter-feed-item ul li a {
  font-size: 14px;
  line-height: 24px;
  color: #0c59db;
  font-size: 12px;
}

.blog-sidebar .widget .twitter-feeds .twitter-feed-item span {
  color: #516381;
  font-size: 12px;
  font-weight: 700;
}

.blog-sidebar .widget .twitter-feeds .twitter-feed-item i {
  position: absolute;
  top: 7px;
  left: 0;
  color: #32beff;
}

.blog-sidebar .widget.instagram-feed-widget ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px;
}

.blog-sidebar .widget.instagram-feed-widget ul li {
  width: 33.33%;
  -webkit-box-flex: 33.33%;
  -ms-flex: 33.33%;
  flex: 33.33%;
  padding: 5px;
}

.blog-sidebar .widget.instagram-feed-widget ul li img {
  width: 100%;
}

.blog-sidebar .widget.tags-widget ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px;
}

.blog-sidebar .widget.tags-widget ul li {
  padding: 5px;
}

.blog-sidebar .widget.tags-widget ul a {
  background: #f5faff;
  color: #616161;
  font-size: 12px;
  line-height: 30px;
  padding: 0 20px;
  font-weight: 700;
}

.blog-sidebar .widget.tags-widget ul a:hover {
  background: #0c59db;
  color: #fff;
}

.blog-sidebar .widget.ad-widget {
  padding: 0;
  border: none;
  position: relative;
}

.blog-sidebar .widget.ad-widget img {
  width: 100%;
}

.blog-sidebar .widget.ad-widget .add-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #0c59db;
  text-align: center;
  padding: 20px 40px;
}

.blog-sidebar .widget.ad-widget .add-content span {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.blog-sidebar .widget.ad-widget .add-content .title {
  font-size: 24px;
  color: #fff;
  white-space: nowrap;
  padding-top: 5px;
}

/*=======  Blog Details  =======*/
.blog-dteails-content {
  color: #81a3bb;
  font-size: 15px;
  border: 2px solid #ededed;
  padding: 40px;
}

@media (max-width: 767px) {
  .blog-dteails-content {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .blog-dteails-content {
    padding: 30px 25px;
  }
}

.blog-dteails-content .cat {
  background: #0c59db;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 3px;
  padding: 0 15px;
  line-height: 30px;
  text-transform: uppercase;
}

.blog-dteails-content .title {
  font-size: 32px;
  padding-top: 25px;
  line-height: 1.3;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-dteails-content .title {
    padding-right: 0;
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .blog-dteails-content .title {
    font-size: 24px;
  }
}

@media (max-width: 575px) {
  .blog-dteails-content .title {
    font-size: 22px;
  }
}

.blog-dteails-content .subtitle {
  font-size: 28px;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .blog-dteails-content .subtitle {
    font-size: 24px;
  }
}

@media (max-width: 575px) {
  .blog-dteails-content .subtitle {
    font-size: 20px;
  }
}

.blog-dteails-content .post-meta {
  padding: 20px 0;
}

.blog-dteails-content .post-meta li {
  display: inline-block;
  font-size: 14px;
  margin-right: 28px;
}

@media (max-width: 575px) {
  .blog-dteails-content .post-meta li {
    margin-right: 10px;
  }
}

.blog-dteails-content .post-meta li a {
  color: #69a1bb;
}

.blog-dteails-content .post-meta li i {
  padding-right: 6px;
}

.blog-dteails-content .divider {
  width: 100%;
  height: 1px;
  background-color: #e3eeff;
  margin: 40px 0;
}

.blog-dteails-content .post-content-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blog-dteails-content .post-content-list li {
  width: 50%;
  -ms-flex-wrap: 0 0 50%;
  flex-wrap: 0 0 50%;
  margin-bottom: 8px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-dteails-content .post-content-list li {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 767px) {
  .blog-dteails-content .post-content-list li {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

.blog-dteails-content .post-content-list li i {
  color: #0c59db;
  margin-right: 10px;
}

.blog-dteails-content blockquote {
  background-color: #f6f6f6;
  padding: 60px;
  border-radius: 15px;
  font-size: 28px;
  font-weight: 700;
  color: #002249;
  text-align: center;
  line-height: 1.3;
  position: relative;
  z-index: 1;
  font-family: "Alegreya Sans";
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-dteails-content blockquote {
    padding: 50px;
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .blog-dteails-content blockquote {
    font-size: 20px;
    padding: 40px 20px;
  }
}

.blog-dteails-content blockquote cite {
  display: block;
  font-size: 14px;
  font-style: normal;
  color: #0c59db;
  line-height: 1;
  margin-bottom: 10px;
  font-family: "Alegreya Sans", sans-serif;
}

.blog-dteails-content blockquote .quote-icon {
  position: absolute;
  right: 60px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

@media (max-width: 767px) {
  .blog-dteails-content blockquote .quote-icon {
    max-width: 100px;
  }
}

.blog-dteails-content .post-footer .related-tag h5,
.blog-dteails-content .post-footer .social-share h5 {
  font-size: 20px;
  margin-bottom: 20px;
}

.blog-dteails-content .post-footer .related-tag ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-dteails-content .post-footer .related-tag ul a {
  background: #f4f4f4;
  color: #81a3bb;
  line-height: 1;
  padding: 8px 25px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
}

.blog-dteails-content .post-footer .related-tag ul a:hover {
  background-color: #0c59db;
  color: #fff;
}

@media (max-width: 575px) {
  .blog-dteails-content .post-footer .social-share {
    margin-top: 30px;
  }
}

.blog-dteails-content .post-footer .social-share h5 {
  text-align: right;
}

@media (max-width: 575px) {
  .blog-dteails-content .post-footer .social-share h5 {
    text-align: left;
  }
}

.blog-dteails-content .post-footer .social-share ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-start;
}

@media (max-width: 575px) {
  .blog-dteails-content .post-footer .social-share ul {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.blog-dteails-content .post-footer .social-share ul a {
  font-size: 16px;
  color: #81a3bb;
  margin-left: 15px;
}

.blog-dteails-content .post-footer .post-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: -1;
}

@media (max-width: 575px) {
  .blog-dteails-content .post-footer .post-nav {
    display: block;
  }
}

.blog-dteails-content .post-footer .post-nav span {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
  color: #81a3bb;
}

.blog-dteails-content .post-footer .post-nav h3 {
  font-size: 28px;
  font-weight: 600;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-dteails-content .post-footer .post-nav h3 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .blog-dteails-content .post-footer .post-nav h3 {
    font-size: 22px;
  }
}

.blog-dteails-content .post-footer .post-nav h3 a {
  color: #002249;
}

.blog-dteails-content .post-footer .post-nav .next-post {
  text-align: right;
}

@media (max-width: 575px) {
  .blog-dteails-content .post-footer .post-nav .next-post {
    text-align: left;
    margin-top: 30px;
  }
}

.blog-dteails-content .post-footer .post-nav::before {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: url(../img/icon/dot-box.png);
  line-height: 1;
}

@media (max-width: 767px) {
  .blog-dteails-content .post-footer .post-nav::before {
    display: none;
  }
}

.blog-dteails-content .post-footer .related-posts .related-title {
  font-size: 26px;
  margin-bottom: 30px;
  font-weight: 600;
}

.blog-dteails-content .post-footer .related-posts .related-post-box {
  margin-bottom: 40px;
}

.blog-dteails-content .post-footer .related-posts .related-post-box .thumb {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
}

@media (max-width: 575px) {
  .blog-dteails-content .post-footer .related-posts .related-post-box .thumb {
    height: 250px;
  }
}

.blog-dteails-content .post-footer .related-posts .related-post-box .content {
  padding: 35px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
  box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
  font-size: 14px;
}

@media (max-width: 991px) {
  .blog-dteails-content .post-footer .related-posts .related-post-box .content {
    padding: 30px;
  }
}

.blog-dteails-content .post-footer .related-posts .related-post-box .content .date {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 10px;
}

.blog-dteails-content .post-footer .related-posts .related-post-box .content .date i {
  color: #0c59db;
  margin-right: 5px;
}

.blog-dteails-content .post-footer .related-posts .related-post-box .content h4 {
  font-size: 22px;
  margin-bottom: 20px;
}

@media (max-width: 575px) {
  .blog-dteails-content .post-footer .related-posts .related-post-box .content h4 {
    font-size: 20px;
  }
}

.blog-dteails-content .post-footer .related-posts .related-post-box .content h4 a {
  color: #002249;
}

.blog-dteails-content .post-footer .related-posts .related-post-box .content h4 a:hover {
  color: #0c59db;
}

.blog-dteails-content .post-footer .post-author {
  border: 2px solid #f3f3f3;
  padding: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
}

@media (max-width: 767px) {
  .blog-dteails-content .post-footer .post-author {
    display: block;
  }
}

@media (max-width: 575px) {
  .blog-dteails-content .post-footer .post-author {
    padding: 25px;
  }
}

.blog-dteails-content .post-footer .post-author .image {
  max-width: 180px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 180px;
  flex: 0 0 180px;
  margin-right: 40px;
}

@media (max-width: 991px) {
  .blog-dteails-content .post-footer .post-author .image {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blog-dteails-content .post-footer .post-author .image {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.blog-dteails-content .post-footer .post-author span {
  font-size: 12px;
  color: #0c59db;
  font-weight: 700;
}

.blog-dteails-content .post-footer .post-author .name {
  font-size: 36px;
  margin-bottom: 15px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-dteails-content .post-footer .post-author .name {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .blog-dteails-content .post-footer .post-author .name {
    font-size: 28px;
  }
}

@media (max-width: 575px) {
  .blog-dteails-content .post-footer .post-author .name {
    font-size: 24px;
  }
}

.comment-template .template-title {
  font-size: 26px;
  margin-bottom: 40px;
}

.comment-template .comments-list li {
  position: relative;
  padding-left: 130px;
}

@media (max-width: 767px) {
  .comment-template .comments-list li {
    padding-left: 0;
    padding-top: 130px;
  }
}

.comment-template .comments-list li .comment-author {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
}

.comment-template .comments-list li .comment-content {
  position: relative;
  font-size: 14px;
}

.comment-template .comments-list li .comment-content h6 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}

.comment-template .comments-list li .comment-content .date {
  font-size: 14px;
  margin-bottom: 25px;
}

.comment-template .comments-list li .comment-content .date i {
  color: #0c59db;
  margin-right: 5px;
}

.comment-template .comments-list li .comment-content .reply-link {
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid #e3eeff;
  font-size: 14px;
  padding: 10px 20px;
  line-height: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-weight: 700;
}

@media (max-width: 575px) {
  .comment-template .comments-list li .comment-content .reply-link {
    position: unset;
    margin-top: 20px;
  }
}

.comment-template .comments-list li .comment-content .reply-link i {
  margin-right: 5px;
}

.comment-template .comments-list li .comment-content .reply-link:hover {
  color: #fff;
  background-color: #0c59db;
  border-color: #0c59db;
}

.comment-template .comments-list li:not(:last-child) {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e3eeff;
}

.comment-template .comments-list li .children {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #e3eeff;
}

@media (max-width: 767px) {
  .comment-template .comments-list li .children {
    margin-left: 40px;
  }
}

.comment-template .comment-form {
  background-color: #f5faff;
  padding: 50px;
}

@media (max-width: 575px) {
  .comment-template .comment-form {
    padding: 30px;
  }
}

.comment-template .comment-form button {
  border: none;
  background-color: #0c59db;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  height: 60px;
  padding: 0px 40px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 575px) {
  .comment-template .comment-form button {
    padding: 0 30px;
    font-size: 14px;
  }
}

.comment-template .comment-form button i {
  margin-right: 10px;
}

.comment-template .comment-form button:hover {
  background-color: #002249;
}

/*=======  Page Title  =======*/
.pricing-tab-btn.nav {
  background: #fff;
  padding: 10px;
}

.pricing-tab-btn.nav li a {
  border-radius: 0;
  padding: 0 35px;
  line-height: 60px;
  color: #69a1bb;
  font-weight: 700;
}

.pricing-tab-btn.nav li a.active {
  color: #fff;
  background: #0c59db;
}

.pricing-tab-btn.dark-style {
  background-color: #002c5f;
}

.pricing-plan-item {
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 5;
  padding: 55px 30px 60px;
  text-align: center;
}

.pricing-plan-item::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.pricing-plan-item > * {
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.pricing-plan-item .plan-name {
  font-weight: 700;
  color: #0c59db;
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
}

.pricing-plan-item .price {
  font-size: 16px;
}

.pricing-plan-item .price span {
  font-size: 70px;
}

.pricing-plan-item .plan-duration {
  color: #81a3bb;
  font-size: 14px;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 33px;
}

.pricing-plan-item .plan-duration::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 42px;
  left: 50%;
  bottom: -20px;
  background: #69a1bb;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0.4;
}

.pricing-plan-item p {
  font-size: 14px;
  color: #69a1bb;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.pricing-plan-item .plans-btn {
  background-color: transparent;
  border: 2px solid #e3eeff;
  font-size: 14px;
  margin-top: 35px;
  width: 100%;
  padding: 15px;
  font-weight: 700;
  color: #0c59db;
}

.pricing-plan-item.dark-style::before {
  background-color: #00152e;
}

.pricing-plan-item.dark-style .price,
.pricing-plan-item.dark-style .plan-duration,
.pricing-plan-item.dark-style .plan-name {
  color: #fff;
}

.pricing-plan-item.dark-style .plans-btn {
  border-color: #1d3d63;
}

.pricing-plan-item:hover::before {
  background-color: #0c59db;
  opacity: 0.9;
}

.pricing-plan-item:hover .plan-duration,
.pricing-plan-item:hover .plan-name,
.pricing-plan-item:hover .price,
.pricing-plan-item:hover p {
  color: #fff;
}

.pricing-plan-item:hover .plan-duration::before {
  width: 100px;
  opacity: 1;
  background-color: #fff;
}

.pricing-plan-item:hover .plans-btn {
  background-color: #0c59db;
  border-color: #0c59db;
  color: #fff;
}

/*======= Tile Gallery =======*/
.tile-gallery-one {
  text-align: right;
}

.tile-gallery-one img {
  border: 11px solid #fff;
  -webkit-box-shadow: 0px 13px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 13px 30px rgba(0, 0, 0, 0.07);
}

.tile-gallery-one .img-one {
  text-align: left;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tile-gallery-one .img-one {
    max-width: 90%;
  }
}

@media (max-width: 767px) {
  .tile-gallery-one .img-one {
    max-width: 90%;
  }
}

.tile-gallery-one .img-two {
  margin-top: -80px;
  position: relative;
  display: inline-block;
  margin-left: auto;
  z-index: 1;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tile-gallery-one .img-two {
    max-width: 90%;
  }
}

@media (max-width: 767px) {
  .tile-gallery-one .img-two {
    max-width: 90%;
  }
}

.tile-gallery-one .img-two::before {
  content: "";
  position: absolute;
  content: "";
  left: -15px;
  bottom: -15px;
  background-color: #0c59db;
  z-index: -1;
  height: 180px;
  width: 180px;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}

.tile-gallery-two {
  text-align: right;
}

.tile-gallery-two .img-one {
  text-align: left;
}

@media (max-width: 575px) {
  .tile-gallery-two .img-one {
    max-width: 90%;
  }
}

.tile-gallery-two .img-two {
  margin-top: -220px;
  position: relative;
  z-index: 1;
  display: inline-block;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tile-gallery-two .img-two {
    max-width: 80%;
  }
}

@media (max-width: 575px) {
  .tile-gallery-two .img-two {
    max-width: 90%;
  }
}

.tile-gallery-two .img-two::before {
  content: "";
  position: absolute;
  content: "";
  right: -15px;
  bottom: -15px;
  background-color: #0c59db;
  z-index: -1;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.tile-gallery-three .img-one {
  text-align: right;
}

@media (max-width: 575px) {
  .tile-gallery-three .img-one {
    max-width: 90%;
  }
}

.tile-gallery-three .img-two {
  margin-top: -220px;
  position: relative;
  z-index: 1;
  display: inline-block;
}

@media (max-width: 575px) {
  .tile-gallery-three .img-two {
    max-width: 90%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tile-gallery-three .img-two {
    max-width: 90%;
  }
}

.tile-gallery-three .img-two img {
  border: 15px solid #f5faff;
}

/*=======  Coming Soon  =======*/
.timer-items .syotimer .syotimer__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .timer-items .syotimer .syotimer__body {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .timer-items .syotimer .syotimer__body {
    display: block;
  }
}

.timer-items .syotimer .syotimer__body .syotimer-cell {
  height: 250px;
  width: 250px;
  border: 10px solid #f5faff;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: center;
  align-content: center;
  margin-bottom: 40px;
}

@media (max-width: 1199px) {
  .timer-items .syotimer .syotimer__body .syotimer-cell {
    height: 200px;
    width: 200px;
  }
}

@media (max-width: 991px) {
  .timer-items .syotimer .syotimer__body .syotimer-cell {
    height: 150px;
    width: 150px;
  }
}

@media (max-width: 767px) {
  .timer-items .syotimer .syotimer__body .syotimer-cell {
    height: 200px;
    width: 200px;
  }
}

@media (max-width: 575px) {
  .timer-items .syotimer .syotimer__body .syotimer-cell {
    margin-left: auto;
    margin-right: auto;
  }
}

.timer-items .syotimer .syotimer__body .syotimer-cell > div {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.timer-items .syotimer .syotimer__body .syotimer-cell::before {
  position: absolute;
  content: "";
  height: 108%;
  width: 108%;
  border-radius: 50%;
  border: 10px solid transparent;
  left: -10px;
  top: -10px;
  z-index: -1;
  border-left-color: #0c59db;
  border-right-color: #0c59db;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (max-width: 1199px) {
  .timer-items .syotimer .syotimer__body .syotimer-cell::before {
    width: 110%;
    height: 110%;
  }
}

@media (max-width: 991px) {
  .timer-items .syotimer .syotimer__body .syotimer-cell::before {
    height: 114%;
    width: 114%;
  }
}

@media (max-width: 767px) {
  .timer-items .syotimer .syotimer__body .syotimer-cell::before {
    width: 110%;
    height: 110%;
  }
}

.timer-items .syotimer .syotimer__body .syotimer-cell .syotimer-cell__value {
  font-size: 50px;
  font-weight: 700;
  font-family: "Alegreya Sans";
  color: #002249;
  line-height: 1;
}

@media (max-width: 991px) {
  .timer-items .syotimer .syotimer__body .syotimer-cell .syotimer-cell__value {
    font-size: 40px;
  }
}

.timer-items .syotimer .syotimer__body .syotimer-cell .syotimer-cell__unit {
  font-weight: 700;
  color: #69a1bb;
}

.timer-items .timer-title {
  margin-top: 60px;
  font-size: 80px;
  text-align: center;
  line-height: 1.1;
}

@media (max-width: 1199px) {
  .timer-items .timer-title {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .timer-items .timer-title {
    font-size: 42px;
  }
}

.error-content {
  padding-top: 100px;
}

.error-content .error-title {
  font-size: 80px;
  text-align: center;
  line-height: 1.1;
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .error-content .error-title {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .error-content .error-title {
    font-size: 42px;
  }
}

/*=======  Privacy  =======*/
.privacy-area .content-box {
  padding-bottom: 25px;
  border-bottom: 1px solid #e3eeff;
  color: #81a3bb;
}

.privacy-area .content-box:last-child {
  border-bottom: none;
}

.privacy-area .content-box h2 {
  margin-bottom: 20px;
}

.privacy-area .content-box h4 {
  max-width: 80%;
  margin-bottom: 15px;
}

.privacy-area .content-box p {
  margin-bottom: 20px;
}

.privacy-area .content-box ul.list {
  margin-top: 15px;
}

.privacy-area .content-box ul.list li {
  position: relative;
  padding-left: 20px;
  padding-bottom: 15px;
  line-height: 20px;
}

.privacy-area .content-box ul.list li:before {
  position: absolute;
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #002249;
  content: "";
}

/*ABOUT DETAIL*/
.doctors-area {
  position: relative;
  z-index: 1;
}
.doctors-area.bg-1a1e42 {
  background-color: var(--whiteColor);
}
.doctors-area.bg-1a1e42::before {
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  content: '';
  height: 35%;
  position: absolute;
  background-color: var(--blackColor);
}
.doctors-area .shape2 {
  bottom: auto;
  top: 188px;
}
.doctors-area.style-two.bg-1a1e42::before {
  height: 53%;
}
.doctors-area.style-two .shape2 {
  top: 208px;
}
.doctors-area .title {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 40px;
  font-size: 20px;
}
.single-doctor-big-box {
  border-radius: 20px;
  margin-bottom: 50px;
  background-color: var(--whiteColor);
  box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.15);
}
.single-doctor-big-box .image {
  border-radius: 20px 0 0 20px;
}
.single-doctor-big-box .image img {
  border-radius: 20px 0 0 20px;
}
.single-doctor-big-box .content {
  padding: 30px 70px;
}
.single-doctor-big-box .content h3 {
  font-size: 22px;
  margin-bottom: 9px;
}
.single-doctor-big-box .content .designation {
  display: block;
  margin-bottom: 15px;
  padding-bottom: 20px;
  color: var(--paragraphColor);
  border-bottom: 1px solid #e0e0e0;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--fontFamily);
}
.single-doctor-big-box .content p {
  margin-bottom: 20px;
}
.single-doctor-big-box .content .social {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-doctor-big-box .content .social li {
  display: inline-block;
  margin-right: 5px;
}
.single-doctor-big-box .content .social li:last-child {
  margin-right: 0;
}
.single-doctor-big-box .content .social li a {
  width: 35px;
  height: 35px;
  display: block;
  font-size: 14px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #eee;
}
.single-doctor-big-box .content .social li a:hover {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--whiteColor);
}
.single-doctor-big-box .content .link-btn {
  position: relative;
  padding-right: 15px;
  display: inline-block;
  color: var(--primaryColor);
  text-decoration: underline;
  font-family: var(--headingFontFamily);
  font-weight: 600;
  font-size: 15px;
}
.single-doctor-big-box .content .link-btn i {
  top: 50%;
  right: -3px;
  font-size: 18px;
  margin-top: 1.3px;
  position: absolute;
  transform: translateY(-50%);
}
.single-doctor-big-box .content .link-btn:hover {
  color: var(--secondaryColor);
  padding-right: 25px;
}
.single-doctor-box {
  margin-bottom: 25px;
  border-radius: 15px;
  background-color: var(--whiteColor);
  box-shadow: 0px 4px 55px rgba(0, 0, 0, 0.15);
}
.single-doctor-box .image {
  z-index: 1;
  overflow: hidden;
  position: relative;
  border-radius: 15px 15px 0 20px;
}
.single-doctor-box .image img {
  border-radius: 15px 15px 0 20px;
  transition: var(--transition);
}
.single-doctor-box .image::before {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  content: '';
  bottom: -2px;
  position: absolute;
  background-color:  #fff;
  clip-path: polygon(100% 100%, 100% 85%, 0% 100%);
}
.single-doctor-box .image .icon {
  z-index: 1;
  right: 50px;
  bottom: 5px;
  width: 65px;
  height: 65px;
  font-size: 38px;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  color: #fff;
  transition: .5s;
  background-color: #63a3c9;
}
.single-doctor-box .image .icon i {
  left: 5px;
  right: 0;
  top: 54%;
  margin-top: 3px;
  position: absolute;
  transform: translateY(-50%);
}
.single-doctor-box .content {
  padding: 35px;
}
.single-doctor-box .content h3 {
  font-size: 22px;
  margin-bottom: 9px;
}
.single-doctor-box .content .designation {
  display: block;
  margin-bottom: 15px;
  padding-bottom: 20px;
  color:  #666666;
  border-bottom: 1px solid #e0e0e0;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--fontFamily);
}
.single-doctor-box .content p {
  margin-bottom: 20px;
}
.single-doctor-box .content .social {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-doctor-box .content .social li {
  display: inline-block;
  margin-right: 5px;
}
.single-doctor-box .content .social li:last-child {
  margin-right: 0;
}
.single-doctor-box .content .social li a {
  width: 35px;
  height: 35px;
  display: block;
  font-size: 14px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #eee;
}
.single-doctor-box .content .social li a:hover {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--whiteColor);
}
.single-doctor-box .content .link-btn {
  position: relative;
  padding-right: 15px;
  display: inline-block;
  color: var(--primaryColor);
  text-decoration: underline;
  font-family: var(--headingFontFamily);
  font-weight: 600;
  font-size: 15px;
}
.single-doctor-box .content .link-btn i {
  top: 50%;
  right: -3px;
  font-size: 18px;
  margin-top: 1px;
  position: absolute;
  transform: translateY(-50%);
}
.single-doctor-box .content .link-btn:hover {
  color: var(--secondaryColor);
  padding-right: 25px;
}
/*.single-doctor-box:hover .image img {
  transform: scale(1.08);
}*/

.single-doctor-box[_ngcontent-gvk-c51] .image[_ngcontent-gvk-c51]:before {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  content: "";
  bottom: -2px;
  position: absolute;
  background-color: var(--whiteColor);
  clip-path: polygon(100% 100%,100% 85%,0% 100%);
}

.single-doctor-box:hover .image .icon {
  background-color: var(--secondaryColor);
  color: var(--whiteColor);
}
.filter-sidebar {
  background-color: #f5f5f5;
  margin-right: 20px;
}
.filter-sidebar .sidebar-title {
  border-bottom: 1px solid #e4e4e4;
  padding: 35px 35px 30px;
  position: relative;
}
.filter-sidebar .sidebar-title h3 {
  font-size: 17px;
  margin-bottom: 0;
}
.filter-sidebar .sidebar-title span {
  top: 50%;
  right: 30px;
  cursor: pointer;
  font-weight: 500;
  position: absolute;
  text-transform: uppercase;
  color: var(--primaryColor);
  transform: translateY(-50%);
}
.filter-sidebar .accordion .accordion-item {
  border: none;
  padding: 35px;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid #e4e4e4;
}
.filter-sidebar .accordion .accordion-item .accordion-button {
  border: 0;
  padding: 0;
  box-shadow: unset;
  color: var(--primaryColor);
  background-color: transparent;
  font-size: 17px;
  font-weight: 600;
  font-family: var(--headingFontFamily);
}
.filter-sidebar .accordion .accordion-item .accordion-button::after {
  display: none;
}
.filter-sidebar .accordion .accordion-item .accordion-button::before {
  right: 0;
  top: 50%;
  transition: 0.3s;
  content: "\f194";
  position: absolute;
  color: var(--primaryColor);
  transform: translateY(-50%);
  font-size: 17px;
  font-weight: normal;
  font-family: Flaticon;
}
.filter-sidebar .accordion .accordion-item .accordion-body {
  padding: 18px 0 0;
}
.filter-sidebar .accordion .accordion-item .accordion-body ul {
  padding-left: 0;
  margin-bottom: -15px;
  list-style-type: none;
}
.filter-sidebar .accordion .accordion-item .accordion-body ul li {
  margin-bottom: 15px;
  display: block;
  font-size: var(--fontSize);
  font-weight: 500;
}
.filter-sidebar .accordion .accordion-item .accordion-body ul li .form-check {
  margin-bottom: 0;
}
.filter-sidebar .accordion .accordion-item .accordion-body ul li .form-check .form-check-input {
  cursor: pointer;
  border-radius: 0;
  box-shadow: unset;
  margin-top: 0.2em;
  border-color: #9c9c9c;
  background-color: transparent;
}
.filter-sidebar .accordion .accordion-item .accordion-body ul li .form-check .form-check-input:checked {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}
.filter-sidebar .accordion .accordion-item .accordion-body ul li .form-check .form-check-label {
  cursor: pointer;
}
.filter-sidebar .accordion .accordion-item:last-child {
  border-bottom: none;
}
.d-none.d-block {
  display: block !important;
}
/* Max width 767px */
@media only screen and (max-width: 767px) {
  .doctors-area.bg-1a1e42::before {
    height: 21%;
  }
  .doctors-area .title {
    padding-bottom: 12px;
    margin-bottom: 35px;
    font-size: 16px;
  }
  .single-doctor-big-box {
    border-radius: 10px;
    margin-bottom: 25px;
  }
  .single-doctor-big-box .image img {
    border-radius: 10px 0 0 10px;
  }
  .single-doctor-big-box .content {
    padding: 20px;
  }
  .single-doctor-big-box .content h3 {
    font-size: 17px;
    margin-bottom: 8px;
  }
  .single-doctor-big-box .content .designation {
    font-size: 14px;
    margin-bottom: 12px;
    padding-bottom: 15px;
  }
  .single-doctor-big-box .content .d-flex {
    display: block !important;
  }
  .single-doctor-big-box .content p {
    margin-bottom: 15px;
  }
  .single-doctor-big-box .content .link-btn {
    font-size: 14px;
    margin-top: 15px;
  }
  .single-doctor-box .image .icon {
    right: 20px;
    bottom: 3px;
    width: 50px;
    height: 50px;
    font-size: 27px;
  }
  .single-doctor-box .content {
    padding: 20px;
  }
  .single-doctor-box .content h3 {
    font-size: 17px;
    margin-bottom: 8px;
  }
  .single-doctor-box .content .designation {
    font-size: 14px;
    margin-bottom: 12px;
    padding-bottom: 15px;
  }
  .single-doctor-box .content .d-flex {
    display: block !important;
  }
  .single-doctor-box .content p {
    margin-bottom: 15px;
  }
  .single-doctor-box .content .link-btn {
    font-size: 14px;
    margin-top: 15px;
  }
  .filter-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .filter-sidebar .sidebar-title {
    padding: 20px;
  }
  .filter-sidebar .sidebar-title h3 {
    font-size: 14px;
  }
  .filter-sidebar .sidebar-title span {
    right: 20px;
  }
  .filter-sidebar .accordion .accordion-item {
    padding: 20px;
  }
  .filter-sidebar .accordion .accordion-item .accordion-button {
    font-size: 14px;
  }
  .filter-sidebar .accordion .accordion-item .accordion-button::before {
    font-size: 14px;
  }
  .filter-sidebar .accordion .accordion-item .accordion-body ul li {
    font-size: 14px;
  }
}
/* Min width 576px to Max width 767px */
/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .doctors-area.style-two.bg-1a1e42::before {
    height: 40%;
  }
  .doctors-area .title {
    margin-bottom: 35px;
    font-size: 18px;
  }
  .single-doctor-big-box {
    margin-bottom: 25px;
    padding: 20px 15px;
  }
  .single-doctor-big-box .image img {
    border-radius: 0;
  }
  .single-doctor-big-box .content {
    padding: 0 0 0 25px;
  }
  .single-doctor-big-box .content h3 {
    font-size: 19px;
  }
  .single-doctor-big-box .content .designation {
    font-size: 14px;
    padding-bottom: 0;
    margin-bottom: 10px;
    border-bottom: none;
  }
  .single-doctor-big-box .content p {
    margin-bottom: 15px;
  }
  .single-doctor-big-box .content .link-btn {
    font-size: 14px;
  }
  .single-doctor-big-box .content .link-btn i {
    font-size: 18px;
    margin-top: 1px;
  }
  .single-doctor-box .image .icon {
    right: 40px;
    bottom: 5px;
    width: 60px;
    height: 60px;
    font-size: 33px;
  }
  .single-doctor-box .content {
    padding: 25px;
  }
  .single-doctor-box .content h3 {
    font-size: 19px;
  }
  .single-doctor-box .content .designation {
    font-size: 14px;
  }
  .single-doctor-box .content p {
    margin-bottom: 15px;
  }
  .single-doctor-box .content .link-btn {
    font-size: 14px;
  }
  .single-doctor-box .content .link-btn i {
    font-size: 18px;
    margin-top: 1px;
  }
  .filter-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .filter-sidebar .sidebar-title {
    padding: 25px;
  }
  .filter-sidebar .sidebar-title h3 {
    font-size: 15px;
  }
  .filter-sidebar .accordion .accordion-item {
    padding: 25px;
  }
  .filter-sidebar .accordion .accordion-item .accordion-button {
    font-size: 15px;
  }
  .filter-sidebar .accordion .accordion-item .accordion-body ul li {
    font-size: 15px;
  }
}
/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .doctors-area.bg-1a1e42::before {
    height: 25%;
  }
  .single-doctor-big-box {
    margin-bottom: 25px;
  }
  .single-doctor-big-box .content {
    padding: 18px 30px;
  }
  .single-doctor-big-box .content .designation {
    padding-bottom: 15px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .single-doctor-big-box .content p {
    margin-bottom: 15px;
  }
  .filter-sidebar {
    margin-right: 0;
  }
  .filter-sidebar .sidebar-title {
    padding: 25px;
  }
  .filter-sidebar .accordion .accordion-item {
    padding: 25px;
  }
}
/* Min width 1200px to Max width 1399px */
/* Min width 1600px */

.doctor-profile-area {
  padding-top: 50px;
}
.profile-description h3 {
  font-size: 20px;
  margin-bottom: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.profile-description h3:not(:first-child) {
  margin-top: 35px;
}
.profile-description p a {
  color: var(--primaryColor);
}
.profile-description p a:hover {
  color: var(--secondaryColor);
}
.profile-description ul, .profile-description ol {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 0;
}
.profile-description ul li, .profile-description ol li {
  color: #000;
  position: relative;
  padding-left: 26px;
  margin-bottom: 15px;
  font-family: var(--headingFontFamily);
  font-weight: 500;
  font-size: 15px;
}
.profile-description ul li:last-child, .profile-description ol li:last-child {
  margin-bottom: 0;
}
.profile-description ul li::before, .profile-description ol li::before {
  left: 0;
  top: 50%;
  content: "\f28b";
  margin-top: -0.5px;
  position: absolute;
  transform: translateY(-50%);
  font-family: Phosphor;
  font-size: 20px;
}
.profile-description ul.expertise, .profile-description ol.expertise {
  max-width: 580px;
  margin-bottom: -15px;
}
.profile-description ul.expertise li, .profile-description ol.expertise li {
  display: inline-block;
  margin-bottom: 15px !important;
  margin-right: 40px !important;
}
.profile-description strong {
  color: var(--blackColor) !important;
}
.profile-description .review-comments .user-review {
  position: relative;
  margin-bottom: 20px;
  padding: 0 0 20px 110px;
  border-bottom: 1px solid #eee;
}
.profile-description .review-comments .user-review img {
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  border-radius: 5px;
  position: absolute;
}
.profile-description .review-comments .user-review .sub-name {
  margin-bottom: 5px;
  font-family: var(--headingFontFamily);
  font-weight: 600;
  font-size: 17px;
}
.profile-description .review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 9px;
}
.profile-description .review-comments .user-review .review-rating .review-stars i {
  line-height: 1;
  font-size: 16px;
  color: #cecfd2;
  margin-right: 5px;
  display: inline-block;
}
.profile-description .review-comments .user-review .review-rating .review-stars i.checked {
  color: #ffc107;
}
.profile-description .review-form {
  margin-top: 30px;
}
.profile-description .review-form h3 {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 10px;
}
.profile-description .review-form .comment-notes span {
  color: red;
}
.profile-description .review-form form {
  margin-top: 20px;
}
.profile-description .review-form form .form-group {
  margin-bottom: 25px;
  text-align: start;
}
.profile-description .review-form form .form-group .form-control {
  height: 55px;
  border: none;
  box-shadow: unset;
  line-height: 55px;
  border-radius: 5px;
  color: var(--blackColor);
  font-size: var(--fontSize);
  background-color: #f9f9f9;
  font-size: var(--fontSize);
  font-weight: 500;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 2px;
}
.profile-description .review-form form .form-group .form-control::placeholder {
  color: var(--paragraphColor);
  transition: var(--transition);
}
.profile-description .review-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.profile-description .review-form form .form-group textarea.form-control {
  height: auto;
  padding-top: 20px;
  line-height: unset;
}
.profile-description .review-form form .rating {
  text-align: start;
  overflow: hidden;
  max-width: 115px;
  margin-top: -5px;
  margin-bottom: 25px;
}
.profile-description .review-form form .rating label {
  width: 23px;
  height: 23px;
  float: right;
  cursor: pointer;
  position: relative;
}
.profile-description .review-form form .rating label:not(:first-of-type) {
  padding-right: 5px;
}
.profile-description .review-form form .rating label:before {
  transition: var(--transition);
  content: "\2605";
  color: #ccc;
  font-size: 27px;
  line-height: 1;
}
.profile-description .review-form form .rating input {
  display: none;
}
.profile-description .review-form form .rating input:checked ~ label:before, .profile-description .review-form form .rating:not(:checked) > label:hover:before, .profile-description .review-form form .rating:not(:checked) > label:hover ~ label:before {
  color: #f6b500;
}
.profile-description .review-form form .form-check {
  cursor: pointer;
  margin-bottom: 0;
  color: var(--blackColor);
}
.profile-description .review-form form .form-check a {
  color: var(--primaryColor);
}
.profile-description .review-form form .form-check a:hover {
  color: var(--secondaryColor);
}
.profile-description .review-form form .form-check .form-check-input {
  margin-top: 0.24em;
  box-shadow: unset !important;
}
.profile-description .review-form form .form-check .form-check-input[type=checkbox] {
  border-radius: 0;
}
.profile-description .review-form form .form-check .form-check-input:checked {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}
.profile-description .review-form form .form-check .form-check-input:focus {
  border-color: var(--primaryColor);
}
.profile-description .review-form form .form-check .form-check-label {
  cursor: pointer;
}
.profile-description .review-form form .default-btn {
  margin-top: 25px;
}
.profile-sidebar {
  padding-left: 35px;
  position: relative;
  margin-top: 0;
/*  margin-top: -210px;*/
}
.profile-sidebar .single-doctor-box {
  margin-bottom: 0;
}
.profile-sidebar .single-doctor-box .content p {
  margin-bottom: 15px;
}
.profile-sidebar .single-doctor-box .content .status {
  color: var(--paragraphColor);
  background-color: #f5f5f5;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 5px;
  padding: 6px 18px;
  margin-top: 5px;
  font-size: 15px;
  font-weight: 700;
}
.profile-sidebar .single-doctor-box .content .rating {
  margin-top: 25px;
}
.profile-sidebar .single-doctor-box .content .rating .star {
  line-height: 0.01;
}
.profile-sidebar .single-doctor-box .content .rating .star i {
  line-height: 0.01;
  color: #ccc;
  margin-right: 4px;
  font-size: 18px;
}
.profile-sidebar .single-doctor-box .content .rating .star i:last-child {
  margin-right: 0;
}
.profile-sidebar .single-doctor-box .content .rating .star i.checked {
  color: #ffc107;
}
.profile-sidebar .single-doctor-box .content .rating span {
  color: var(--blackColor);
  display: inline-block;
  margin-left: 7px;
  font-size: var(--fontSize);
  font-weight: 600;
}
.profile-sidebar .single-doctor-box .content .rating span:nth-child(3) {
  color: var(--secondaryColor);
}
.profile-sidebar .single-doctor-box .content .default-btn {
  padding-right: 65px;
  text-align: start;
  margin-top: 25px;
  display: block;
  width: 100%;
}
.profile-sidebar .single-doctor-box .content .social {
  margin-top: 25px;
}

.cut{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .doctor-profile-area {
    padding-top: 0;
  }
  .doctor-profile-area .col-lg-8 {
    order: 2;
  }
  .doctor-profile-area .col-lg-4 {
    order: 1;
  }
  .profile-description h3 {
    font-size: 17px;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  .profile-description ol, .profile-description ul {
    margin-top: 20px;
  }
  .profile-description ol li, .profile-description ul li {
    padding-left: 28px;
    font-size: 13px;
  }
  .profile-description ol.expertise, .profile-description ul.expertise {
    max-width: 100%;
  }
  .profile-description ol.expertise li, .profile-description ul.expertise li {
    display: block;
    margin-right: 0 !important;
  }
  .profile-description .review-comments .user-review {
    margin-bottom: 15px;
    padding: 0 0 15px 0;
  }
  .profile-description .review-comments .user-review img {
    width: 90px;
    height: 90px;
    position: relative;
    margin-bottom: 15px;
  }
  .profile-description .review-comments .user-review .review-stars i {
    font-size: 15px;
  }
  .profile-description .review-comments .user-review .sub-name {
    font-size: 14px;
  }
  .profile-description .review-form form .form-group {
    margin-bottom: 20px;
  }
  .profile-description .review-form form .form-group .form-control {
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .profile-description .review-form form .form-check .form-check-input {
    margin-top: 0.3em;
  }
  .profile-description .review-form form .default-btn {
    margin-top: 20px;
  }
  .profile-sidebar {
    padding-left: 0;
    margin-top: -60px;
    margin-bottom: 40px;
  }
  .profile-sidebar .single-doctor-box .content .rating {
    margin-top: 20px;
    display: block !important;
  }
  .profile-sidebar .single-doctor-box .content .rating .star {
    margin-bottom: 10px;
  }
  .profile-sidebar .single-doctor-box .content .rating .star i {
    font-size: 15px;
  }
  .profile-sidebar .single-doctor-box .content .rating span {
    display: inline-block;
    font-size: 14px;
    margin-left: 0;
    margin-right: 5px;
  }
  .profile-sidebar .single-doctor-box .content .status {
    padding: 5px 15px;
    font-size: 13px;
    margin-top: 0;
  }
  .profile-sidebar .single-doctor-box .content .social {
    margin-top: 20px;
  }
  .profile-sidebar .single-doctor-box .content .default-btn {
    margin-top: 20px;
    padding-right: 60px;
  }
  .profile-sidebar .single-doctor-box .content .default-btn.style-two {
    padding-left: 43px;
  }
  .profile-sidebar .single-doctor-box .content .default-btn.style-two i {
    font-size: 18px;
  }
}
/* Min width 576px to Max width 767px */
/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .doctor-profile-area {
    padding-top: 0;
  }
  .doctor-profile-area .col-lg-4 {
    order: 1;
  }
  .doctor-profile-area .col-lg-8 {
    order: 2;
  }
  .profile-description h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .profile-description ol li, .profile-description ul li {
    font-size: 14px;
  }
  .profile-description .review-comments .user-review .review-rating .review-stars i {
    font-size: 15px;
  }
  .profile-description .review-comments .user-review .sub-name {
    font-size: 15px;
  }
  .profile-sidebar {
    padding-left: 0;
    margin-top: -100px;
    margin-bottom: 40px;
  }
  .profile-sidebar .single-doctor-box .image .icon {
    right: 40px;
    bottom: 45px;
  }
  .profile-sidebar .single-doctor-box .content {
    padding: 35px;
  }
  .profile-sidebar .single-doctor-box .content .rating .star i {
    font-size: 15px;
  }
  .profile-sidebar .single-doctor-box .content .rating span {
    font-size: 15px;
  }
}
/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .profile-sidebar {
    padding-left: 0;
    margin-top: -220px;
  }
  .profile-sidebar .single-doctor-box .image .icon {
    right: 30px;
    bottom: 0;
  }
  .profile-sidebar .single-doctor-box .content {
    padding: 25px 20px;
  }
  .profile-sidebar .single-doctor-box .content .rating .star i {
    font-size: 15px;
  }
  .profile-sidebar .single-doctor-box .content .rating span {
    margin-left: 4px;
    font-size: 14px;
  }
  .profile-sidebar .single-doctor-box .content .status {
    font-size: 14px;
  }
  .profile-sidebar .single-doctor-box .content .default-btn.style-two {
    padding-left: 14px;
    font-size: 15px;
  }
  .profile-sidebar .single-doctor-box .content .default-btn.style-two i {
    display: none;
  }
}
/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .profile-sidebar {
    padding-left: 0;
  }
  .profile-sidebar .single-doctor-box .content {
    padding: 30px;
  }
  .profile-sidebar .single-doctor-box .content .rating span {
    font-size: 15px;
  }
  .profile-sidebar .single-doctor-box .content .default-btn {
    font-size: 14px;
  }
}
/* Min width 1600px */

